import { useMemo, useState, useCallback } from 'react';
import { Button } from 'rsuite';

import { calcAverageNutrients } from '../../../components/nutrients/nutrientsData';
import NutritionGroup from './NutritionGroup';

const MAX_VISIBLE = 2;

const getItems = (showAll, allItems) => {
  return showAll ? allItems : allItems.slice(0, MAX_VISIBLE);
};

const Nutrition = ({ data, profile }) => {
  const { diary } = data;

  const allItems = useMemo(() => calcAverageNutrients(diary, profile, true) || [], [diary, profile]);
  const [items, setItems] = useState(getItems(false, allItems));

  const showExpand = allItems.length > MAX_VISIBLE;
  const [allVisible, setAllVisible] = useState(false);

  const onExpand = useCallback((showAll) => {
    setItems(getItems(showAll, allItems || []))
    setAllVisible(showAll);
  }, [allItems, setItems, setAllVisible]);

  return (
    <div>
      <h2>
        Nutrition
      </h2>

      <div className="container">
        <div className="row g-5">
          {items.map((group) => 
            <NutritionGroup 
              key={group.category} 
              category={group.category} 
              items={group.items} />
          )}
        </div>

        {showExpand && (
          <Button 
            appearance="default" 
            className="btn-gadget-2 mt-3" 
            onClick={() => onExpand(!allVisible)}>
            {allVisible ? 'Hide all' : 'View all'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Nutrition;