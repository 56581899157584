import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import WarningRound from '@rsuite/icons/WarningRound';

import { Button, IconButton } from 'rsuite';

import LoaderWrapper from '../../../components/common/LoaderWrapper';

import { STORE } from '../../../store';
import { useAsyncAppState } from '../../../hooks/useAsyncAppState';
import { useCapability } from '../../../hooks/useCapability';
import { uploadResultFiles } from '../../../services/upload';

import './upload.scss';

const DEFAULT_STATE = {
  selected: [],
  rejected: []
};

const UploadDocsForm = (props) => {
  useCapability('useUpload');

  const { onRefresh, isUnique, disabled } = props;

  const [uploadedFiles, uploadFilesAsync] = useAsyncAppState(
    STORE.areas.documents.upload, 
    uploadResultFiles);

  const [files, setFiles] = useState(DEFAULT_STATE);
  const filesRef = useRef(files);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 100,
    accept: {
      'application/octet-stream': ['.mm'],
      'text/html': ['.html', '.htm'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    validator: (file) => null
  });

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    const rejected = [...fileRejections];
    const selected = [...filesRef.current.selected];

    acceptedFiles.forEach((acceptedFile) => {
      if (selected.find((selectedFile) => selectedFile.name === acceptedFile.name)) {
        rejected.push({
          file: acceptedFile,
          error: {
            code: 'Duplicate',
            messsage: 'Already selected'
          }
        })
      }
      else {
        selected.push(acceptedFile);
      }
    });

    setFiles({
      selected,
      rejected
    })
  }, [acceptedFiles, fileRejections, setFiles]);

  const onDeleteDocument = useCallback((e, name) => {
    const selected = [...filesRef.current.selected];
    const rejected = [...filesRef.current.rejected];
    const index = selected.findIndex((selectedFile) => selectedFile.name === name);
    if (index >= 0) {
      selected.splice(index, 1);
      setFiles({
        selected,
        rejected
      })
    }
    e.stopPropagation();
  }, []);

  const onUpload = useCallback(async (e) => {
    e.stopPropagation();
    
    if (!files.selected.length) {
      return;
    }

    const data = await uploadFilesAsync({ files: files.selected, isUnique });
    setFiles(DEFAULT_STATE);
    onRefresh(data);
  }, [files, isUnique, onRefresh, uploadFilesAsync]);

  return (
    <LoaderWrapper items={[uploadedFiles]} show>
      <div className="drop-zone" {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="d-flex justify-content-center">
          <p>
            <b>Drag and drop files to upload</b><br />
            <Button
              appearance="default" 
              className="btn-gadget-2">
              Choose Files
            </Button>
          </p>
        </div>
        <div className="text-center">
          {files.selected.map((selected) => (
            <div key={selected.name} className="file-item" title={selected.name}>
              <FolderFillIcon />
              <span>{selected.name}</span>
              <IconButton
                onClick={(e) => onDeleteDocument(e, selected.name)} 
                circle appearance="link" 
                icon={<WarningRound />} />
            </div>
          ))}

          {files.selected.length > 0 && !disabled && (
            <Button
              appearance="default" 
              className="btn-gadget-2 mt-2"
              onClick={onUpload}>
              Upload Files
            </Button>
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default UploadDocsForm;
