import { useRef, useState, forwardRef, useEffect, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd';
import { Accordion, Popover, Whisper, Dropdown } from 'rsuite';

import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';

import Tree from '../shared/tree/Tree';
import classNames from 'classnames';

const PANEL_TYPE = 'ViewerPanel';
const ACTION_SAVE = 'save';
const ACTION_DELETE = 'delete';
const ACTION_REMOVE = 'remove';

const MenuPopover = forwardRef((props, ref) => {
  const { onSelect, ...rest } = props;
  return (
    <Popover ref={ref} full {...rest}>
      <Dropdown.Menu onSelect={onSelect}>
        <Dropdown.Item
          icon={<span className="material-icons">save</span>}
          eventKey={ACTION_SAVE}>
          Save File  
        </Dropdown.Item>
        <Dropdown.Item
          icon={<span className="material-icons">delete</span>}
          eventKey={ACTION_DELETE}>
          Delete File  
        </Dropdown.Item>
        <Dropdown.Separator></Dropdown.Separator>
        <Dropdown.Item
          icon={<span className="material-icons">remove</span>}
          eventKey={ACTION_REMOVE}>
          Remove Panel
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  )
});

const ViewerPanel = (props) => {
  const ref = useRef(null);
  const { 
    index, 
    data,
    expanded, 
    active,
    onClick,
    onMovePanel, 
    onShowUrl, 
    onSave,
    onDelete,
    onRemove,
    onAddExternal,
    onOpenExternal, 
    onDeleteExternal, 
  } = props;

  const popoverRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen && popoverRef.current) {
      popoverRef.current.open();
    }
  }, [menuOpen]);

  const onMenuClick = useCallback((e) => {
    setMenuOpen(true);
    e.stopPropagation();
  }, [setMenuOpen]);

  const onMenuClose = useCallback((e) => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const onMenuSelect = useCallback((menuAction, e) => {
    if (menuAction === ACTION_DELETE) {
      onDelete(data._id);
    }
    else if (menuAction === ACTION_REMOVE) {
      onRemove(data._id);
    }
    else if (menuAction === ACTION_SAVE) {
      onSave(data);
    }

    if (popoverRef.current) {
      popoverRef.current.close();
    }

    setMenuOpen(false);
    e.stopPropagation();
  }, [data, setMenuOpen, onDelete, onRemove, onSave]);

  // Drag n Drop
  const [{ handlerId }, drop] = useDrop({
    accept: PANEL_TYPE,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMovePanel(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: PANEL_TYPE,

    item: () => {
      return { id: data._id, index };
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Accordion.Panel 
      ref={ref}
      id={`panel_${data._id}`}
      data-handler-id={handlerId}
      onClick={() => onClick(data._id)}
      className={classNames('logic-tree', active && 'active')}
      style={{ opacity: isDragging ? 0 : 1 }}
      caretAs={ArrowDownLineIcon}
      expanded={expanded}
      header={(
        <div className="file-name">
          <Whisper
            ref={popoverRef}
            placement="topStart"
            controlId={`panel_menu_${data._id}`}
            onClose={onMenuClose}
            trigger={['click']}
            speaker={<MenuPopover onSelect={onMenuSelect} />}>
            <a href="#menu" 
              className="rs-btn-icon rs-btn-icon-circle rs-btn" 
              onClick={onMenuClick} >
              <span className="material-icons">menu</span>
            </a>  
          </Whisper>
          <span>
            {data.title}
          </span>
        </div>
      )}>
        
      <Tree 
        node={data.node} 
        onOpenUrl={onShowUrl}
        onAddExternal={(node) => onAddExternal(data, node)}
        onOpenExternal={onOpenExternal}
        onDeleteExternal={onDeleteExternal} 
      />
    </Accordion.Panel>
  );
};

export default ViewerPanel;