import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Modal, Input } from 'rsuite';

const SaveDialog = (props) => {
  const { 
    data,
    onSave,
    onClose,
  } = props;
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const inputRef = useRef();

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setName(data.node.name);

      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [data]);

  const onSaveClick = useCallback(async () => {
    if (title.length && name.length)
    data.title = title;
    data.node.name = name;
    onSave(data);
  }, [data, title, name, onSave]);

  return (
    <Modal className="round-modal"
      open={Boolean(data)} 
      onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <h2>
          Logic Map File
        </h2>
        <div className="mb-3">
          <label>Title</label>
          <Input ref={inputRef}
            value={title} 
            onKeyDown={(e) => e.key === 'Enter' && onSaveClick()}
            onChange={(value) => setTitle(value)}></Input>
        </div>
        <div>
          <label>Root Node Name</label>
          <Input
            value={name} 
            onKeyDown={(e) => e.key === 'Enter' && onSaveClick()}
            onChange={(value) => setName(value)}></Input>
        </div>
        <div>
          <Button
            appearance="default" 
            className="btn-gadget-2 mt-3"
            disabled={!title.length || !name.length}
            onClick={onSaveClick}>
            Save Logic
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SaveDialog;
