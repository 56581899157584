import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Checkbox,
  Grid, 
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  LinearProgress,
  Paper,
  IconButton
} from '@mui/material';

import BackupIcon from '@mui/icons-material/Backup';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { 
  resolveGeneProfile, 
  getProgressGeneProfile,
  getResultGeneProfile,
} from '../../services/genetics';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import UploadDocsForm from '../uploadDocs/UploadDocsForm';

import GeneCrudDialog from './GeneCrudDialog';
import GeneChartDialog, { getRandomColor } from './GeneChartDialog';
import ResolveGenePdfDialog from './ResolveGenePdfDialog';

export const CRUD_LOAD = 'load';
export const CRUD_SAVE = 'save';

const GENE_MAP_GRID = 'map';
const GENE_LINKS_GRID = 'links';
const GENE_KEGGS_GRID = 'keggs';

const ResolveGeneUploadProfilePage = () => {
  const [result, setResult] = useState(null);
  const [keggs, setKeggs] = useState(null);
  const [isFull, setIsFull] = useState(false);
  const [sort, setSort] = useState({
    column: 'frequency',
    asc: false
  });

  const [sortLinks, setSortLinks] = useState({
    column: 'score',
    asc: false
  });

  const [sortKeggs, setSortKeggs] = useState({
    column: 'score',
    asc: false
  });

  const [resolveProfile, resolveProfileAsync] = useAsyncAppState(
    STORE.areas.genetics.resolveProfile,
    resolveGeneProfile
  );

  const [progressProfile, progressProfileAsync] = useAsyncAppState(
    STORE.areas.genetics.progressProfile,
    getProgressGeneProfile
  );

  const [resultProfile, resultProfileAsync] = useAsyncAppState(
    STORE.areas.genetics.resultProfile,
    getResultGeneProfile
  );

  const [
    openCrudDialog, 
    closeCrudDialog,
    crudDialogData 
  ] = useDialog(STORE.areas.genetics.crudDialog);

  const [
    openChartDialog, 
    closeChartDialog,
    chartDialogData 
  ] = useDialog(STORE.areas.genetics.chartDialog);

  const [
    openPdfDialog, 
    closePdfDialog,
    pdfDialogData 
  ] = useDialog(STORE.areas.genetics.resolvePdfDialog);

  const navigate = useNavigate();

  const progressPolling = useCallback(async () => {
    const data = await progressProfileAsync();
    if (data.progress >= 0) {
      if (data.progress < 100) {
        setTimeout(progressPolling, 5 * 1000);
      }
      else {
        const profileResult = await resultProfileAsync();
        setResult(profileResult);
      }
    }
  }, [setResult, resultProfileAsync, progressProfileAsync]); 

  const onUpload = useCallback(async (uploadResult) => {
    if (uploadResult?.length) {
      setResult(null);
      const success = await resolveProfileAsync({
        fileName: uploadResult[0].file.name, 
        isFull
      });

      if (success) {
        progressPolling();
      }
    }
  }, [isFull, progressPolling, resolveProfileAsync]);
  
  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const onSave = useCallback(() => {
    openCrudDialog({
      mode: CRUD_SAVE
    });
  }, [openCrudDialog]);

  const onPdf = useCallback(() => {
    openPdfDialog({ name: 'Profile Test', result });
  }, [result, openPdfDialog]);

  const onLoad = useCallback(async () => {
    const ids = await openCrudDialog({
      mode: CRUD_LOAD
    });
    if (ids) {
      const profileResult = await resultProfileAsync(ids);
      if (Array.isArray(profileResult)) {
        setResult(profileResult);
      }
      else {
        setResult(profileResult.result);
        setKeggs(profileResult.keggs);
      }
    }
  }, [setResult, openCrudDialog, resultProfileAsync]);

  const onStats = useCallback(async (geneCode, geneNameParam = null) => {
    let geneName = geneNameParam;
    if (!geneName && result) {
      geneName = result.geneMap.find((gene) => gene.geneCode === geneCode)?.geneName;
    }
    openChartDialog({ geneCode, geneName });
  }, [openChartDialog, result]);

  const onSort = useCallback((column, table) => {
    const setSortState = table === GENE_MAP_GRID ? 
      setSort : 
      (table === GENE_LINKS_GRID ? setSortLinks : setSortKeggs);

    setSortState((currentSort) => {
      let asc = true;
      if (currentSort.column === column) {
        asc = !currentSort.asc;
      }

      return {
        column,
        asc
      }
    });
  }, [setSort, setSortLinks]);

  useEffect(() => {
    setResult((currentResult) => {
      if (!currentResult) {
        return null;
      }
      
      const geneMap = [...currentResult.geneMap].sort(
        (left, right) => {
          if (sort.column === 'significants') {
            if (left[sort.column] === right[sort.column]) {
              return 0;
            }
            const leftArray = left[sort.column].split(',');
            const leftValue = { length: leftArray.length, value: left[sort.column] };
            const rightArray = right[sort.column].split(',');
            const rightValue = { length: rightArray.length, value: right[sort.column] };;
            if (leftValue.length === rightValue.length) {
              if (leftValue.value > rightValue.value) {
                return sort.asc ? 1 : -1;
              }
              else {
                return sort.asc ? -1 : 1;
              }
            }
            else {
              if (leftValue.length > rightValue.length) {
                return sort.asc ? 1 : -1;
              }
              else {
                return sort.asc ? -1 : 1;
              }
            }
          }
          else {
            if (left[sort.column] === right[sort.column]) {
              return 0;
            }
            if (left[sort.column] > right[sort.column]) {
              return sort.asc ? 1 : -1;
            }
            else {
              return sort.asc ? -1 : 1;
            }
          }
        }
      );
      
      return {
        ...currentResult,
        geneMap
      }
    });
  }, [sort, setResult]);

  useEffect(() => {
    setResult((currentResult) => {
      if (!currentResult) {
        return null;
      }
      
      const geneLinks = [...currentResult.geneLinks].sort(
        (left, right) => {
          if (left[sortLinks.column] === right[sortLinks.column]) {
            return 0;
          }
          if (left[sortLinks.column] > right[sortLinks.column]) {
            return sortLinks.asc ? 1 : -1;
          }
          else {
            return sortLinks.asc ? -1 : 1;
          }
        }
      );
      
      return {
        ...currentResult,
        geneLinks
      }
    });
  }, [sortLinks, setResult]);

  useEffect(() => {
    setKeggs((currentKeggs) => {
      if (!currentKeggs) {
        return null;
      }
      
      const keggs = [...currentKeggs].sort(
        (left, right) => {
          if (left[sortKeggs.column] === right[sortKeggs.column]) {
            return 0;
          }
          if (left[sortKeggs.column] > right[sortKeggs.column]) {
            return sortKeggs.asc ? 1 : -1;
          }
          else {
            return sortKeggs.asc ? -1 : 1;
          }
        }
      );
      
      return keggs;
    });
  }, [sortKeggs, setKeggs]);

  useEffect(() => {
    progressPolling();
  }, [progressPolling]);

  const progressValue = useMemo(() => {
    return progressProfile.data?.progress >= 0 && progressProfile.data?.progress <= 100 ? 
      progressProfile.data?.progress :
      -1;
  }, [progressProfile]);

  const inProgress = useMemo(() => {
    return progressValue >= 0 && progressValue < 100;
  }, [progressValue]);

  const profileColors = useMemo(() => {
    const colors = {};
    if (result?.name?.length) {
      result.name.forEach((name) => colors[name] = getRandomColor(1, 150));
    }
    return colors;
  }, [JSON.stringify(result?.name)]);

  return (
    <Layout>
      <LoaderWrapper items={[resolveProfile, resultProfile]} />
      <Box className="vertical-flex h-100">
        <h3>
          Upload & Resolve Gene Links
        </h3>
        <Box className="vertical-flex-grow">
          <Grid container className="justify-content-center pb-1">  
            <Grid item xs={12} md={8} lg={6}>
              <UploadDocsForm isUnique 
                onRefresh={onUpload} 
                disabled={inProgress} />

              <Paper>
                <FormControlLabel 
                  control={
                    <IconButton onClick={onSave} disabled={result === null || Boolean(result._id)}>
                      <BackupIcon />
                    </IconButton>
                  } 
                  label="Save" />

                <FormControlLabel 
                  control={
                    <IconButton onClick={onLoad} disabled={inProgress}>
                      <CloudDownloadIcon />
                    </IconButton>
                  } 
                  label="Load" />

                <FormControlLabel 
                  control={
                    <IconButton onClick={onPdf} disabled={result === null}>
                      <PictureAsPdfIcon />
                    </IconButton>
                  }
                  label="Pdf" />

                <FormControlLabel 
                  control={
                    <Checkbox 
                      checked={isFull}
                      onChange={(event) => setIsFull(event.target.checked)}
                    />
                  } 
                  label="All items (with Curated)" />
              </Paper>  
            </Grid>
          </Grid>
          
          <Grid container className="justify-content-center pb-1">  
            <Grid item>
              {progressValue >= 0 && !result && (
                <>
                  <h3>{progressValue}%</h3>
                  <LinearProgress 
                    variant="determinate"
                    value={progressValue} 
                    sx={{ minWidth: '20rem' }} />
                </>
              )}

              {result && (
              <>
                {result.name && <h3>{result.name.map((name) => <>{name}<br /></>)}</h3>}
                {keggs && (
                  <>
                    <Grid container className="justify-content-center pb-1">  
                      <Grid item xs={12} md={10} lg={6}>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: '20rem' }} size="small" className="genemap-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Button onClick={() => onSort('name', GENE_KEGGS_GRID)}>
                                    Profile
                                    {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => onSort('kegg', GENE_KEGGS_GRID)}>
                                    KEGG
                                    {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => onSort('enzyme', GENE_KEGGS_GRID)}>
                                    Enzyme
                                    {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => onSort('gene', GENE_KEGGS_GRID)}>
                                    Gene
                                    {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => onSort('protein', GENE_KEGGS_GRID)}>
                                    Protein Name
                                    {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button onClick={() => onSort('metIon', GENE_KEGGS_GRID)}>
                                    Metals / Ions
                                    {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {keggs.map((kegg) => (
                                <TableRow hover key={`${kegg._id}_${kegg.keggId}`}>
                                  <TableCell><b style={{ color: profileColors[kegg.name] || 'black' }}>{kegg.name}</b></TableCell>
                                  <TableCell>{kegg.kegg}</TableCell>
                                  <TableCell>{kegg.enzyme}</TableCell>
                                  <TableCell>{kegg.gene}</TableCell>
                                  <TableCell>{kegg.protein}</TableCell>
                                  <TableCell>{kegg.metIon}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <Divider className="my-1"/>
                  </>
                )}

                <Paper sx={{ maxWidth: '95vw', overflow: 'auto' }}>
                  <Table size="small" className="genemap-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Button onClick={() => onSort('name', GENE_MAP_GRID)}>
                            Profile
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('snippetCode', GENE_MAP_GRID)}>
                            Snippet
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('geneCode', GENE_MAP_GRID)}>
                            Gene Code
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('geneName', GENE_MAP_GRID)}>
                            Gene Name
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('altAllele', GENE_MAP_GRID)}>
                            Alt. Allele
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('frequency', GENE_MAP_GRID)}>
                            Frequency  
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('cadd', GENE_MAP_GRID)}>
                            CADD
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('significants', GENE_MAP_GRID)}>
                            Significant
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('variants', GENE_MAP_GRID)}>
                            Variants
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>  
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('position', GENE_MAP_GRID)}>
                            Position
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>  
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('chromosome', GENE_MAP_GRID)}>
                            Chromosome
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>  
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => onSort('inheritance', GENE_MAP_GRID)}>
                            Inherit
                            {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result.geneMap.map((item) => (
                        <TableRow hover key={`${item._id}_${item.snippetCode}`}>
                          <TableCell>
                            <b style={{ color: profileColors[item.name] || 'black' }}>{item.name}</b>
                          </TableCell>
                          <TableCell>
                            {item.snippetCode}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => onStats(item.geneCode, item.geneName)}>
                              {item.geneCode}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {item.geneName}
                          </TableCell>
                          <TableCell>
                            {item.altAllele}
                          </TableCell>
                          <TableCell>
                            {item.frequency}
                          </TableCell>
                          <TableCell>
                            {item.cadd}
                          </TableCell>
                          <TableCell>
                            {item.significants}
                          </TableCell>
                          <TableCell>
                            {item.variants}
                          </TableCell>
                          <TableCell>
                            {item.position}
                          </TableCell>
                          <TableCell>
                            {item.chromosome}
                          </TableCell>
                          <TableCell style={{ 
                            backgroundColor: (item.inheritance === 'Hetero' ? '#D0CD43' : '#f33'),
                            color: 'white'
                          }}>
                            {item.inheritance}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
                <Divider className="my-1"/>
                <Grid container className="justify-content-center pb-1">  
                  <Grid item xs={12} md={10} lg={6}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: '20rem' }} size="small" className="genemap-table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Button onClick={() => onSort('name', GENE_LINKS_GRID)}>
                                Name
                                {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button onClick={() => onSort('left', GENE_LINKS_GRID)}>
                                Gene Left
                                {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button onClick={() => onSort('right', GENE_LINKS_GRID)}>
                                Gene Right
                                {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button onClick={() => onSort('score', GENE_LINKS_GRID)}>
                                Score
                                {sortLinks.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result.geneLinks.map((link) => (
                            <TableRow hover key={`${link._id}_${link.left}${link.right}`}>
                              <TableCell>
                                <b style={{ color: profileColors[link.name] || 'black' }}>{link.name}</b>
                              </TableCell>
                              <TableCell>
                                <Button onClick={() => onStats(link.left)}>
                                  {link.left}
                                </Button>
                              </TableCell>
                              <TableCell>
                              <Button onClick={() => onStats(link.right)}>
                                  {link.right}
                                </Button>
                              </TableCell>
                              <TableCell>
                                {link.score}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
              )}
            </Grid>
          </Grid>  
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>

      <GeneCrudDialog
        handleClose={closeCrudDialog}
        visible={Boolean(crudDialogData.visible)}
        mode={crudDialogData.data?.mode}
      />

      <GeneChartDialog
        handleClose={closeChartDialog}
        visible={Boolean(chartDialogData.visible)}
        geneLinks={result?.geneLinks}
        geneCode={chartDialogData.data?.geneCode}
        geneName={chartDialogData.data?.geneName}
      />

      <ResolveGenePdfDialog
        handleClose={closePdfDialog}
        visible={Boolean(pdfDialogData.visible)}
        name={pdfDialogData.data?.name}
        result={pdfDialogData.data?.result}
      />
    </Layout>
  );
}

export default ResolveGeneUploadProfilePage;
