import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { resolveGeneFile } from '../../services/genetics';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import UploadDocsForm from '../uploadDocs/UploadDocsForm';

const ResolveGeneUploadPage = () => {
  const [result, setResult] = useState(null);
  const [sort, setSort] = useState({
    column: 'frequency',
    asc: false
  });

  const [resolveFile, resolveFileAsync] = useAsyncAppState(
    STORE.areas.genetics.resolveFile,
    resolveGeneFile
  );

  const navigate = useNavigate();

  const onUpload = useCallback(async (uploadResult) => {
    const fileResult = await resolveFileAsync(uploadResult.name);
    setResult(fileResult);
  }, [resolveFileAsync]);
  
  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const onSort = useCallback((column) => {
    setSort((currentSort) => {
      let asc = true;
      if (currentSort.column === column) {
        asc = !currentSort.asc;
      }

      return {
        column,
        asc
      }
    });
  }, [setSort]);

  useEffect(() => {
    if (!result) {
      return;
    }

    const sampleIds = Object.keys(result);
    if (sampleIds.length) {
      const sampleId = sampleIds[0];
      setResult((currentResult) => {
        const geneMap = [...currentResult[sampleId].geneMap].sort(
          (left, right) => {
            if (left[sort.column] === right[sort.column]) {
              return 0;
            }
            if (left[sort.column] > right[sort.column]) {
              return sort.asc ? 1 : -1;
            }
            else {
              return sort.asc ? -1 : 1;
            }
          }
        );

        return {
          ...currentResult,
          [sampleId]: {
            ...currentResult[sampleId],
            geneMap
          }
        }
      });
    }
  }, [sort, result, setResult]);

  const firstSample = useMemo(() => {
    if (!result) {
      return null;
    }
    const sampleIds = Object.keys(result);
    return sampleIds.length > 0 ? result[sampleIds[0]] : null;
  }, [result]);

  return (
    <Layout>
      <LoaderWrapper items={[resolveFile]} />
      <Box className="vertical-flex h-100">
        <h3>
          Upload & Resolve Gene Links
        </h3>
        <Box className="vertical-flex-grow">
          <Grid container className="justify-content-center pb-1">  
            <Grid item xs={12} md={6} lg={3}>
              <UploadDocsForm onRefresh={onUpload} isUnique />
            </Grid>
          </Grid>
          
          {firstSample && (
            <>
              <h4>Profile: {Object.keys(result)[0]}</h4>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '20rem' }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Button onClick={() => onSort('snippetCode')}>
                          Snippet
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => onSort('geneCode')}>
                          Gene Code
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => onSort('geneName')}>
                          Gene Name
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => onSort('altAllele')}>
                          Alt. Allele
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => onSort('frequency')}>
                          Frequency  
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                      <TableCell>Significant</TableCell>
                      <TableCell>Variants</TableCell>
                      <TableCell>
                        <Button onClick={() => onSort('inheritance')}>
                          Inherit
                          {sort.asc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {firstSample.geneMap.map((item) => (
                      <TableRow hover key={item.snippetCode}>
                        <TableCell>
                          {item.snippetCode}
                        </TableCell>
                        <TableCell>
                          {item.geneCode}
                        </TableCell>
                        <TableCell>
                          {item.geneName}
                        </TableCell>
                        <TableCell>
                          {item.altAllele}
                        </TableCell>
                        <TableCell>
                          {item.frequency}
                        </TableCell>
                        <TableCell>
                          {item.significants}
                        </TableCell>
                        <TableCell>
                          {item.variants}
                        </TableCell>
                        <TableCell style={{ 
                          backgroundColor: (item.inheritance === 'Hetero' ? '#D0CD43' : '#f33'),
                          color: 'white'
                        }}>
                          {item.inheritance}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider className="my-1"/>
              <Grid container className="justify-content-center pb-1">  
                <Grid item xs={12} md={10} lg={6}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '20rem' }} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Gene Left</TableCell>
                          <TableCell>Gene Right</TableCell>
                          <TableCell>Score</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {firstSample.geneLinks.map((link) => (
                          <TableRow hover key={`${link.left}${link.right}`}>
                            <TableCell>
                              {link.left}
                            </TableCell>
                            <TableCell>
                              {link.right}
                            </TableCell>
                            <TableCell>
                              {link.score}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  className="w-100"
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>
    </Layout>
  );
}

export default ResolveGeneUploadPage;
