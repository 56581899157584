import React, { useRef, useEffect } from 'react';

import '../../css/index.scss';
import logo from '../../assets/img/albright-logo.svg';

const RATIO = 7 / 12;
const LogicViewerLayout = (props) => {
  const { children, viewUrl, rightBlock } = props;
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.close();
    }

    if (viewUrl) {
      const width = window.screen.width;
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=${width * (1 - RATIO)},height=1000,left=${width * RATIO},top=0`;
      popupRef.current = window.open(viewUrl, '', params)
    }
  }, [viewUrl]);

  return (
    <div className="container-fluid rs-suite">
      <nav className="navbar">
        <a className="navbar-brand" href="/home">
          <img src={logo} alt="AlBright"></img>
        </a>
      </nav>
      <div className="row">
        <div className="col col-xl-7">
          <main>
            {children}
          </main>
        </div>
        <div className="d-none d-xl-block col-xl-5">
          {rightBlock}
        </div>
      </div>
    </div>
  );
}

export default LogicViewerLayout;
