import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { IconButton } from 'rsuite';
import FileDownloadIcon from '@rsuite/icons/FileDownload';

import classNames from 'classnames';

const TreeNode = (props) => {
  const { 
    node, 
    isRoot, 
    isExpanded, 
    selectedIds,
    onSelect, 
    onOpenUrl
  } = props;
  const [expanded, setExpanded] = useState(isExpanded);
  const hasChildren = useMemo(() => Boolean(node.children?.length), [node]);
  const isSelected = useMemo(() => selectedIds.indexOf(node.id) >= 0, [selectedIds, node]);

  const isMounted = useRef(false);
  const childrenRef = useRef(null);
  const collapseRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (childrenRef.current && collapseRef.current) {
      const height = collapseRef.current.clientHeight;
      if (expanded) {
        if (height) {
          childrenRef.current.style.maxHeight = `${height}px`;
          setTimeout(() => {
            childrenRef.current.style.maxHeight = 'none';
          }, 500);
        }
        else {
          childrenRef.current.style.maxHeight = 'none';
        }
      }
      else {
        if (height) {
          childrenRef.current.style.maxHeight = `${height}px`;
          setTimeout(() => {
            childrenRef.current.style.maxHeight = '0';
          }, 10);
        }
        else {
          childrenRef.current.style.maxHeight = '0';
        }
      }
    }
  }, [expanded]);

  const onNodeClick = useCallback((e, node) => {
    if (onSelect) {
      onSelect(node);
    }

    if (hasChildren) {
      setExpanded(!expanded);
    }
    e.stopPropagation();
  }, [hasChildren, expanded, onSelect, setExpanded]);

  const onUrlClick = useCallback((e, node) => {
    if (onOpenUrl) {
      onOpenUrl(node);
    }
    e.stopPropagation();
  }, [onOpenUrl]);

  let childNodes = null;

  if (hasChildren && (expanded || isMounted.current)) {
    const topChildren = node.children.slice(0, node.children.length - 1);
    const bottomChildren = node.children.slice(node.children.length - 1);
    
    childNodes = (
      <div ref={childrenRef} className={classNames('tree-node-children')}>
        <div ref={collapseRef} className="tree-node-children-collapse">
          <div className="tree-node-children-point"></div>
          {topChildren.length > 0 && (
            <div className="tree-node-children-content top-children">
              {topChildren.map((child) => 
                <TreeNode 
                  key={child.id} 
                  node={child} 
                  selectedIds={selectedIds}
                  onSelect={onSelect}
                  onOpenUrl={onOpenUrl}
                />)}
            </div>
          )}
          {bottomChildren.length > 0 && (
            <div className="tree-node-children-content">
              {bottomChildren.map((child) => 
                <TreeNode 
                  key={child.id} 
                  node={child}
                  selectedIds={selectedIds}
                  onSelect={onSelect} 
                  onOpenUrl={onOpenUrl}
                />)}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!node) {
    return null;
  }

  return (
    <div className={classNames('tree-node', isRoot && 'is-root')}>
      {!isRoot && <div className="tree-node-border"></div>}

      <div 
        className={classNames('tree-node-content', 
          hasChildren && 'has-children', 
          isSelected && 'selected' )}
        onClick={(e) => onNodeClick(e, node)}>
        <div className="d-flex align-items-center">
          <span>{node.name}</span>
          {node.link && (
            <IconButton
              circle
              onClick={(e) => onUrlClick(e, node)} 
              appearance="subtle" 
              icon={<FileDownloadIcon />} />
          )}  
        </div>    
      </div>
      {childNodes}
    </div>
  );
};


export default TreeNode;