const getNetDataRecursive = (item, parentNode, result) => {
  if (item.node) {
    result.id = item.node.ID;
    result.name = item.node.TEXT.length ? item.node.TEXT.trim() : 'No name';
    result.parent = parentNode;

    if (item.node.LINK) {
      result.link = item.node.LINK;
    }

    if (item.node.children) {
      item.node.children.forEach((child) => {
        if (child.node) {
          const resultChild = {};
          getNetDataRecursive(child, result, resultChild);

          if (!result.children) {
            result.children = [];
          };
          result.children.push(resultChild);
        }
      });
    }
  }
};

export const getNetData = (file, mapData) => {
  let node = null;

  if (mapData?.map?.children?.length) {
    node = {};
    getNetDataRecursive(mapData.map.children[0], null, node);
  }

  return {
    file,
    node
  };
};
