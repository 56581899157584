import axios from 'axios';

import { calcWeight } from '../components/nutrients/nutrientsData';

const capitalize = (word) => {
  if (!word?.length) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);;
};

const normalizeVariants = (variants) => {
  const values = variants.split(',')
    .map((word) => word.trim())
    .filter((word) => word.length);

  const result = [];
  values.forEach((variant) => {
    const value = variant
      .split('_')
      .filter((word) => word.length && word !== 'variant')
      .map((word) => capitalize(word))
      .join(' ');

    result.push(value);  
  });

  return result.join('; ');
};

const normalizeSignificance = (significants) => {
  const values = significants.split(',')
    .map((word) => word.trim())
    .filter((word) => word.length);
  
  return values.length === 1 ? capitalize(values[0]) : 'Conflicting';
};

const normailzeGenetics = (data) => {
  const records = data.genetics?.geneMap;
  if (records?.length) {
    records.forEach((record) => {
      record.variants = normalizeVariants(record.variants);
      record.significants = normalizeSignificance(record.significants);
      record.geneName = capitalize(record.geneName);
    });
  }
};

const calcNutrients = (data) => {
  const foodAllItems = data.diary;
  if (foodAllItems) {
    Object.keys(foodAllItems).forEach((targetDate) => {
      const foodItems = foodAllItems[targetDate];
      foodItems.forEach((foodItem) => {
        foodItem.weight = calcWeight(
          foodItem.foodData, 
          foodItem.foodType, 
          foodItem.portionId, 
          foodItem.quantity);
        
        if (foodItem.portionId && foodItem.foodData?.portions) {
          foodItem.portion = foodItem.foodData.portions
            .find((portion) => portion._id === foodItem.portionId);
        }
      });
    });
  }
};

export const loadReportData = async (patientEmail) => {
  const url = `${process.env.REACT_APP_API_URL}/medReport/${patientEmail}`;
  const response = await axios.get(url);
  normailzeGenetics(response.data);
  calcNutrients(response.data);
  return response.data;
};

export const loadSymptomsData = async (args) => {
  const { patientEmail, options } = args;
  const url = `${process.env.REACT_APP_API_URL}/medReport/symptoms/${patientEmail}`;
  const response = await axios.put(url, options);
  
  return response.data;
};