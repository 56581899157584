import React, { useState, useCallback, useEffect } from 'react';
import { Checkbox, Button, Modal, Table } from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

export const FILES_OPEN_ALL = 'all';
export const FILES_OPEN_ONE = 'one';

const OpenDialog = (props) => {
  const { 
    openMode,
    onOpen,
    onClose,
    namesData,
    linkFrom
  } = props;

  const [idsSelected, setIdsSelected] = useState([]);

  useEffect(() => {
    if (openMode) {
      setIdsSelected([]);
    }
  }, [openMode, setIdsSelected]);

  const onClickFile = useCallback(async (e, id) => {
    const checked = e.target.checked;
    if (openMode === FILES_OPEN_ALL) {
      if (checked) {
        setIdsSelected([...idsSelected, id]);      
      }
      else {
        setIdsSelected(idsSelected.filter((nodeId) => nodeId !== id));
      }
    }

    if (openMode === FILES_OPEN_ONE) {
      if (checked) {
        setIdsSelected([id]);      
      }
      else {
        setIdsSelected([]);
      }
    }
    
  }, [openMode, idsSelected, setIdsSelected]);

  return (
    <Modal className="round-modal"
      open={Boolean(openMode)} 
      onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <h2>
          {linkFrom ? `Link "${linkFrom.name}" with:` : 'Logic Viewer Files'}
        </h2>

        {namesData?.loaded && (
          <Table data={namesData.data} autoHeight className="round-table">
            <Column 
              width={60} 
              align="center" 
              verticalAlign="center">
              <HeaderCell></HeaderCell>
              <Cell style={{ padding: 0 }}>
                {(rowData) => (
                  <Checkbox 
                    checked={idsSelected.indexOf(rowData._id) >= 0} 
                    onClick={(e) => onClickFile(e, rowData._id)} />
                )}
              </Cell>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>File Name</HeaderCell>
              <Cell dataKey="title" />
            </Column>
          </Table>
        )}

        <div>
          <Button
            appearance="default" 
            className="btn-gadget-2 mt-3"
            disabled={!idsSelected?.length}
            onClick={() => onOpen(idsSelected)}>
            {openMode === FILES_OPEN_ALL && 'Open Files'}
            {openMode === FILES_OPEN_ONE && 'Select File'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OpenDialog;
