import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';

import classNames from 'classnames';
import TreeNodeEditor from './TreeNodeEditor';

import { getExpanded } from './treeData';

const TreeNode = (props) => {
  const { 
    node, 
    requests,
    onAction,
  } = props;

  const isRoot = useMemo(() => !Boolean(node.parent), [node.parent]);
  const hasChildren = useMemo(() => Boolean(node.children?.length), [node.children]);
  const [expanded, setExpanded] = useState(getExpanded(node, requests) || false);
  
  const isMounted = useRef(false);
  const childrenRef = useRef(null);
  const collapseRef = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (childrenRef.current && collapseRef.current) {
      const height = collapseRef.current.clientHeight;
      if (expanded) {
        if (height) {
          childrenRef.current.style.maxHeight = `${height}px`;
          setTimeout(() => {
            childrenRef.current.style.maxHeight = 'none';
          }, 500);
        }
        else {
          childrenRef.current.style.maxHeight = 'none';
        }
      }
      else {
        if (height) {
          childrenRef.current.style.maxHeight = `${height}px`;
          setTimeout(() => {
            childrenRef.current.style.maxHeight = '0';
          }, 10);
        }
        else {
          childrenRef.current.style.maxHeight = '0';
        }
      }
    }
  }, [node, expanded]);

  useEffect(() => {
    const newExpanded = getExpanded(node, requests);
    if (newExpanded !== null) {
      setExpanded(newExpanded);
    }
  }, [node, requests]);

  useEffect(() => {
    if (!hasChildren) {
      setExpanded(false);
    }
  }, [hasChildren, setExpanded]);

  const onNodeClick = useCallback((e) => {
    setExpanded(!expanded);
    e.stopPropagation();
  }, [expanded]);

  let childNodes = null;

  if (hasChildren && (expanded || isMounted.current)) {
    const topChildren = node.children.slice(0, node.children.length - 1);
    const bottomChildren = node.children.slice(node.children.length - 1);
    
    childNodes = (
      <div ref={childrenRef} className={classNames('tree-node-children')}>
        <div ref={collapseRef} className="tree-node-children-collapse">
          <div className="tree-node-children-point"></div>
          {topChildren.length > 0 && (
            <div className="tree-node-children-content top-children">
              {topChildren.map((child) => 
                <TreeNode 
                  key={child.id} 
                  node={child} 
                  requests={expanded ? requests : []}
                  onAction={onAction}
                />)}
            </div>
          )}
          {bottomChildren.length > 0 && (
            <div className="tree-node-children-content">
              {bottomChildren.map((child) => 
                <TreeNode 
                  key={child.id} 
                  node={child} 
                  requests={expanded ? requests : []}
                  onAction={onAction}
                />)}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!node) {
    return null;
  }

  return (
    <div className={classNames('tree-node', isRoot && 'is-root')}>
      {!isRoot && <div className="tree-node-border"></div>}

      <div 
        onClick={onNodeClick}
        className={classNames('tree-node-content',  hasChildren && 'has-children')} >
        <div className="d-flex align-items-center">
          <TreeNodeEditor 
            node={node}
            requests={requests}
            onAction={onAction}
          />
        </div>    
      </div>
      {childNodes}
    </div>
  );
};


export default TreeNode;