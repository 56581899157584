import axios from 'axios';

export const loadComments = async({ target, filter = '' }) => {
  const url = `${process.env.REACT_APP_API_URL}/comment-data?target=${target}&filter=${filter}`;
  const response = await axios.get(url);
  return response.data;
};

export const loadPolling = async({ target, lastAt }) => {
  const url = `${process.env.REACT_APP_API_URL}/comment-polling?target=${target}&lastAt=${lastAt}`;
  const response = await axios.get(url);
  return response.data;
};

export const addMessage = async(data) => {
  const url = `${process.env.REACT_APP_API_URL}/comment-message`;
  const response = await axios.post(url, data);
  return response.data;
};

export const editMessage = async(data) => {
  const url = `${process.env.REACT_APP_API_URL}/comment-message`;
  const response = await axios.put(url, data);
  return response.data;
};

export const replyMessage = async(data) => {
  const url = `${process.env.REACT_APP_API_URL}/reply-message`;
  const response = await axios.put(url, data);
  return response.data;
};

export const deleteMessage = async(id) => {
  const url = `${process.env.REACT_APP_API_URL}/delete-message/${id}`;
  const response = await axios.post(url);
  return response.data;
};

export const deleteAttachment = async({ id, fileId }) => {
  const url = `${process.env.REACT_APP_API_URL}/delete-attachment/${id}/${fileId}`;
  const response = await axios.post(url);
  return response.data;
};