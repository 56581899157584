import React, { useCallback, useState, useRef, useEffect, forwardRef } from 'react';
import { IconButton, Popover, Whisper, Dropdown } from 'rsuite';
import { getActionControls, ACTION_UI, ACTION} from './treeData';

const MenuPopover = forwardRef((props, ref) => {
  const { items, onSelect, ...rest } = props;
  return (
    <Popover ref={ref} full {...rest}>
      <Dropdown.Menu onSelect={onSelect}>
        {items.map((item) => (
          <Dropdown.Item
            key={item.name}
            icon={<span className="material-icons">{item.icon}</span>}
            eventKey={item}>
            {item.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  )
});

const TreeNodeButtons = (props) => {
  const {
    node,
    action,
    disabled,
    onAction
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(null);
  const [buttonItems, setButtonItems] = useState(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    const actionItems = getActionControls(node, action, disabled);
    if (actionItems?.length) {
      const newMenuItems = actionItems.filter((item) => item.ui === ACTION_UI.menu);
      setMenuItems(newMenuItems.length ? newMenuItems : null);

      const newButtonItems = actionItems.filter((item) => item.ui === ACTION_UI.button);
      setButtonItems(newButtonItems.length ? newButtonItems : null);
    }
    else {
      setMenuItems(null);
      setButtonItems(null);
    };
  }, [node, action, disabled, setMenuItems]);

  useEffect(() => {
    if (menuOpen && popoverRef.current) {
      popoverRef.current.open();
    }
  }, [menuOpen]);

  const onButtonClick = useCallback((e, buttonAction) => {
    onAction(buttonAction);
    e.stopPropagation();
  }, [onAction]);
  
  const onMenuClick = useCallback((e) => {
    setMenuOpen(true);
    e.stopPropagation();
  }, [setMenuOpen]);

  const onMenuSelect = useCallback((menuAction, e) => {
    onAction(menuAction);
    
    if (popoverRef.current) {
      popoverRef.current.close();
    }

    setMenuOpen(false);
    e.stopPropagation();
  }, [setMenuOpen, onAction]);

  const onMenuClose = useCallback((e) => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <div className="action-buttons">
      {buttonItems && buttonItems.map((buttonAction) => (
        <IconButton
          circle
          key={buttonAction.name}
          className="action-button"
          onClick={(e) => onButtonClick(e, buttonAction)} 
          appearance="subtle" 
          icon={<span className="material-icons">{buttonAction.icon}</span>} />
      ))}

      {menuItems && (
        <IconButton
          circle
          className="action-button" 
          onClick={onMenuClick} 
          appearance="subtle" 
          icon={<span className="material-icons">menu</span>} />
      )}

      {menuOpen && (
        <Whisper
          ref={popoverRef}
          placement="topEnd"
          controlId={`node_menu_${node.id}`}
          onClose={onMenuClose}
          speaker={<MenuPopover items={menuItems} onSelect={onMenuSelect} />}>
        </Whisper>
      )}
    </div>
  );
};


export default TreeNodeButtons;