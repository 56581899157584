
import React, { useCallback, useState, useRef } from 'react';
import ConfirmDialog from './ConfirmDialog';

const useConfirmDialog = (title, okTitle = null) => {
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState('Continue?');
  const onCloseResult = useRef(null);

  const onClose = useCallback((result) => {
    if (onCloseResult.current) {
      onCloseResult.current(result);
    }
    setOpen(false);
  }, [setOpen]);

  const onConfirm = useCallback(async (question) => {
    setOpen(true);
    setQuestion(question);
    return new Promise((onResult) => {
      onCloseResult.current = onResult;
    });
  }, [setOpen]);

  const renderConfirmDialog = () => 
    <ConfirmDialog 
      open={open}
      title={title} 
      question={question}
      onClose={onClose}
      okTitle={okTitle} />

  return [onConfirm, renderConfirmDialog];    
};

export default useConfirmDialog;