import { useEffect } from 'react';

import { STORE } from '../../../store';
import { loadReportData } from '../../../services/medReport';
import { loadProfile } from '../../../services/userProfile';
import { useAsyncAppState  } from '../../../hooks/useAsyncAppState';
import LoaderWrapper from '../../../components/common/LoaderWrapper';

import Layout from '../../layout/Layout';
import ReportHeader from './ReportHeader';
import PatientDetails from './PatientDetails';
import Symptoms from './Symptoms';
import Genetics from './Genetics';
import Medication from './Medication';
import Supplements from './Supplements';
import Nutrition from './Nutrition';
import './report.scss';

const MedicalReportPage = () => {
  const [reportData, loadReportDataAsync] = useAsyncAppState(
    STORE.areas.medReport.load,
    loadReportData
  );

  const [reportProfile, loadReportProfileAsync] = useAsyncAppState(
    STORE.areas.medReport.loadProfile, 
    loadProfile);

  useEffect(() => {
    loadReportProfileAsync('val.martinchik.albright@gmail.com')
    loadReportDataAsync('val.martinchik.albright@gmail.com');
  }, [loadReportDataAsync, loadReportProfileAsync]);

  return (
    <Layout className="rs-suite">
      <LoaderWrapper items={[reportData, reportProfile]}>
        <ReportHeader data={reportData.data} />
        <PatientDetails data={reportData.data} />
        <Symptoms data={reportData.data} />
        <Medication data={reportData.data} />
        <Supplements data={reportData.data} />
        <Genetics data={reportData.data} />
        <Nutrition data={reportData.data} profile={reportProfile.data} />
      </LoaderWrapper>
    </Layout>
  )
};

export default MedicalReportPage;