import { Button, IconButton, Modal } from 'rsuite';
import icon from '../../../assets/img/medication.png';
import { useState } from 'react';

const Medication = ({ data }) => {
  const { medication } = data;
  const [modalData, setModalData] = useState(null);
  return (
    <div>
      <h2>
        Medication
      </h2>

      <div className="row">
        {medication.map((medItem) => (
          <div key={medItem.medData.setid} className="col-md-6 col-lg-3 d-flex">
            <div className="med-icon">
              <IconButton
                onClick={() => setModalData(medItem)} 
                circle appearance="default" 
                icon={<img src={icon} alt="Medication" /> } />
            </div>
            <div className="med-item">
              <Button 
                onClick={() => setModalData(medItem)} 
                className="med-name" appearance="link">
                {medItem.medData.name}
              </Button>
              <div className="med-producer">
                {medItem.medData.packager}
              </div>
              <div className="med-quantity">
                Quantity: {medItem.quantity}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal className="round-modal"
        open={Boolean(modalData)} 
        onClose={() => setModalData(null)}>
        <Modal.Header />
        {modalData && (
          <Modal.Body className="green">
            <h3><img src={icon} alt="Medication" />MEDICATION</h3>
            <h2>
              {modalData.medData.name}
            </h2>
            Quantity: {modalData.quantity}
            <hr></hr>
            <iframe title="MEDICATION" className="med-iframe" src={modalData.medData.href}></iframe>
          </Modal.Body>)}
      </Modal>
    </div>
  );
};

export default Medication;