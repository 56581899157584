import classNames from "classnames";
import { useMemo } from "react";

const getLeftPercent = (value, range) => {
  const result = (value - range[0]) / (range[1] - range[0]) * 100;
  return result < 0 ? 0 : result;
};

const isOutRange = (value, normalRange) => {
  return value < normalRange[0] || value > normalRange[1];
};

const Indicator = (props) => {
  const {
    className,
    range, 
    step, 
    value,
    valueUnit,
    normalRange,
    hasGradient,
    lowHigh
  } = props;

  const dots = useMemo(() => {
    if (!step) return null;

    const result = [];
    let current = range[0];
    while (current <= range[1]) {
      result.push(current);
      current += step;
      if (step < 1) {
        current = Math.round(10000 * current) / 10000;
      }
    };
    return result;
  }, [step, range]);

  return (
    <div className="indicator-content">
      {lowHigh && <div className={classNames('indicator-edge', hasGradient && 'has-gradient')}>low</div>}
      <div className={classNames('indicator', className)}>
        <div className={classNames('indicator-line', hasGradient && 'has-gradient')}></div>
        <div className={classNames('indicator-value', normalRange && isOutRange(value, normalRange) && 'red')} 
          style={{ left: `${getLeftPercent(value, range)}%` }}>
          <div className="indicator-value-popup">
            {value > 0 ? value : 0} {valueUnit && valueUnit}
          </div>
        </div>
        {normalRange && (
          <div className="indicator-norma" 
            style={{ 
              left: `${getLeftPercent(normalRange[0], range)}%`,
              width: `${getLeftPercent(normalRange[1], range) - getLeftPercent(normalRange[0], range)}%` 
            }}>
          </div>
        )}
        {dots && (
          <div className="indicator-dots">
            {dots.map((dot) => (
              <div key={dot} 
                className="indicator-dot" 
                style={{ left: `${getLeftPercent(dot, range)}%` }}>{dot}</div>
            ))}
          </div>
        )}  
      </div>
      {lowHigh && <div className="indicator-edge">high</div>}
    </div>
  );
};

export default Indicator;