export const ELEMENT_GROUPS = {
  'Other Biomarkers': [
    'Adiponectin',
    'Leptin',
    'Ammonia',
    'Creatinine',
    'Cystatin C',
    'Ethanolamine',
    'Glucose',
    'Glycomark',
    'HDL',
    'hsCRP',
    'Insulin',
    'LDL',
    'Lp(a)',
    'Non-HDL',
    'OxLDL',
    'Phosphoethanolamine',
    'PLAC',
    'sdLDL',
    'Total Cholesterol',
    'Triglycerides',
    'Urea',
    'VLDL',
  ],  
  'Metals - Toxic': [
    'Antimony',
    'Arsenic',
    'Beryllium',
    'Cadmium',
    'Gadolinium',
    'Lead',
    'Mercury',
    'Palladium',
    'Tellurium',
    'Thallium',
    'Thorium',
    'Uranium',
    'Nickel',
    'Vanadium',
    'Aluminum',
    'Tin'
  ],
  'Metals - Inert': [
    'Barium',
    'Bismuth',
    'Platinum',
    'Boron',
    'Cesium',
    'Germanium',
    'Rubidium',
    'Silver',
    'Strontium',
    'Sulfur',
    'Titanium',
    'Tungsten',
    'Zirconium'
  ],
  'Metals - Essential': [
    'Lithium',
    'Calcium',
    'Chromium',
    'Cobalt',
    'Copper',
    'Iodine',
    'Iron',
    'Magnesium',
    'Manganese',
    'Molybdenum',
    'Phosphorus',
    'Potassium',
    'Selenium',
    'Sodium',
    'Zinc',
  ],
  'Markers of muscle degradaon': [
    '3-Methylhistidine'
  ],
  'Markers of dietary meat intake': [
    '1-Methylhistidine'
  ],
  'Amino acid metabolites': [
    'Alpha-Aminoadipate',
    'Alpha-Aminoadipitate',
    'Alpha-amino-N-butyrate',
    'Anserine',
    'Beta-alanine',
    'Beta-aminoisobutyrate',
    'Carnosine',
    'Citrulline',
    'Cystathionine',
    'Gamma-aminobutyrate',
    'Homocystine',
    'Hydroxyproline',
    'Hydroyxyproline',
    'Methionine Sulfoxide',
    'Ornithine',
    'Phosphoserine',
    'Sarcosine',
    'Taurine'
  ],
  'Amino Acids': [
    'Alanine',
    'Arginine',
    'Asparagine',
    'Aspartate',
    'Cysteine',
    'Cystine',
    'Glutamate',
    'Glutamine',
    'Glycine',
    'Histidine',
    'Isoleucine',
    'Leucine',
    'Lysine',
    'Methionine',
    'Phenylalanine',
    'Proline',
    'Serine',
    'Threonine',
    'Tryptophan',
    'Tyrosine',
    'Valine'
  ]
};

