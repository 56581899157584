import React, { useEffect, useCallback, useRef } from 'react';
import {
  Route,
  BrowserRouter,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import ProfilesMapPage from './components/profilesMap/ProfilesMapPage';
import SymptomsTimelinePage from './components/timeline/SymptomsTimelinePage';
import NutrientsTrackPage from './components/nutrients/NutrientsTrackPage';
import AverageNutrientsPage from './components/nutrients/reports/AverageNutrientsPage';

import MedicinesTrackPage from './components/medicines/MedicinesTrackPage';
import MetabolomicsPage from './components/casePage/metabolomicsPage/MetabolomicsPage';

import SurveyPage from './components/survey/SurveyPage';
import SurveyCategoryPage from './components/survey/SurveyCategoryPage';
import SurveyCompletePage from './components/survey/SurveyCompletePage';
import SurveyResultPage from './components/survey/SurveyResultPage';

import ProfileDocs from './components/uploadDocs/ProfileDocs';
import ResolveGenePage from './components/genetics/ResolveGenePage';
import ResolveGeneUploadPage from './components/genetics/ResolveGeneUploadPage';
import ResolveGeneUploadProfilePage from './components/genetics/ResolveGeneUploadProfilePage';
import MetaGenePage from './components/metaGene/MetaGenePage';

import MedicalReportPage from './rs-components/reports/medical/MedicalReportPage';
import LogicViewerPage from './rs-components/logicViewer/LogicViewerPage';

import Addons from './components/layout/Addons';
import Construction from './components/layout/Construction';
import Login from './components/login/Login';
import Home from './components/home/Home';
import Layout from './components/layout/Layout';
import LoaderWrapper from './components/common/LoaderWrapper';

import { STORE } from './store';
import { OAuthPopup } from '@tasoskakour/react-use-oauth2';
import { useSignedIn } from './hooks/useSignedIn';
import { useAsyncAppState } from './hooks/useAsyncAppState';

import { loadUser, getCapability } from './services/session';
import { loadProfile } from './services/userProfile';
import { loadSurveyCategories } from './services/survey';
import { loadSymptoms } from './services/symptom';

import 'swiper/css';
import 'swiper/css/free-mode';
import './styles/main.css';

const NotFound = ({signedIn}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!signedIn) {
      navigate('/');
    }
  }, [navigate, signedIn]);

  return (
    <Layout>
      <h3 style={{ textAlign: 'center' }}>
        <SentimentVeryDissatisfiedIcon /><br />
        Ups... Page not found
      </h3>
    </Layout>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#CB8921',
      darker: '#684411',
      contrastText: '#FFFFFF'
    }
  },
});

const App = () => {
  const [signedIn] = useSignedIn();
  const [user, loadUserAsync] = useAsyncAppState(STORE.common.user, loadUser);
  const [profile, loadProfileAsync] = useAsyncAppState(STORE.common.profile, loadProfile);
  const [symptoms, loadSymptomsAsync] = useAsyncAppState(STORE.common.symptoms, loadSymptoms);
  const [surveyCategories, loadSurveyCategoriesAsync] = useAsyncAppState(
    STORE.areas.survey.category.load,
    loadSurveyCategories
  );

  const reloadUser = useRef(!user.data);

  const loadAppData = useCallback(async() => {
    if (reloadUser.current) {
      await loadUserAsync();
    }
    await loadProfileAsync();
    await loadSymptomsAsync();
    await loadSurveyCategoriesAsync();
  }, [loadUserAsync, loadProfileAsync, loadSymptomsAsync, loadSurveyCategoriesAsync]);

  useEffect(() => {
    if (signedIn) {
      loadAppData();
    }
  }, [signedIn, loadAppData]);

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <LoaderWrapper items={[profile, symptoms, surveyCategories]} show={!signedIn}>
          <BrowserRouter>
            <Routes>
              <Route element={<Login />} path="/" exact />
              <Route element={<OAuthPopup />} path="/callback" />

              {signedIn && <>
                <Route path="/home" element={<Home />} />
                <Route path="/timeline" element={<SymptomsTimelinePage />} />
                <Route path="/nutrients" element={<NutrientsTrackPage />} />
                <Route path="/nutritions-averages" element={<AverageNutrientsPage />} />

                <Route path="/medicines" element={<MedicinesTrackPage />} />  

                <Route path="/survey-category" element={<SurveyCategoryPage />} />
                <Route path="/survey" element={<SurveyPage />} />
                <Route path="/survey-complete" element={<SurveyCompletePage />} />
                <Route path="/survey-result" element={<SurveyResultPage />} />

                <Route path="/profiles-map" element={<ProfilesMapPage />} />
                <Route path="/construction" element={<Construction />} />

                <Route path="/gene-resolve" element={<ResolveGenePage />} />
                <Route path="/gene-resolve-file" element={<ResolveGeneUploadPage />} />
                <Route path="/gene-resolve-profile" element={<ResolveGeneUploadProfilePage />} />

                <Route path="/metabolomics" element={<MetabolomicsPage />} />
                <Route path="/metagene-resolve" element={<MetaGenePage />} />

                <Route path='/medical-report' element={<MedicalReportPage />} />
                <Route path='/logic-viewer' element={<LogicViewerPage />} />
                
                {getCapability(user, 'useUpload') && <>
                  <Route path="/document-upload" element={<ProfileDocs />} />
                </>}
              </>}
              <Route path="*" element={<NotFound signedIn={signedIn}/>} />
            </Routes>
          </BrowserRouter>
          {signedIn && <Addons />}
        </LoaderWrapper>
      </ConfirmProvider>
    </ThemeProvider>
  );
};


export default App;
