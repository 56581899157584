import React from 'react';
import Indent from './Indent';
import MessageCard from './MessageCard';

const ReplyThread = (props) => {
  const { 
    user,
    replyId,
    filter,
    editId,
    parent,
    messages,
    setEditId,
    setReplyId,
    onReplySend,
    onEditSend,
    onDelete,
    onDeleteFileStart,
    onDeleteFileEnd
  } = props;

  const threadMessages = messages[parent._id];

  return (
    <div className="messages-thread">
      {(threadMessages || []).map((replyData, index) =>
        <div key={replyData._id} className="d-flex">
          <Indent className={index === 0 ? 'intend-corner' : 'intend-empty'} />
          <MessageCard
            user={user}
            data={replyData}
            replyCount={0}
            replyParent={parent}
            filter={filter}
            editId={editId}
            replyId={replyId}
            setEditId={setEditId}
            setReplyId={setReplyId}
            onReplySend={onReplySend}
            onEditSend={onEditSend}
            onDelete={onDelete}
            onDeleteFileStart={onDeleteFileStart}
            onDeleteFileEnd={onDeleteFileEnd}
          >
            {/* <ReplyThread 
              user={user}
              parent={replyData}
              messages={messages}
              replyId={replyId}
              editId={editId}
              setReplyId={setReplyId}
              setEditId={setEditId}
              onReplySend={onReplySend}
              onEditSend={onEditSend} /> */}
          </MessageCard>
        </div>  
      )}
    </div>
  );
}

export default ReplyThread;
