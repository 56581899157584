import React, { useRef, useState, useEffect, useReducer, useCallback, useMemo } from 'react';
import moment from 'moment';

import { 
  Box, 
  Button,
  Collapse,
  Grid, 
  Divider,
} from '@mui/material';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router';

import { STORE } from '../../store';
import { useDialog } from '../../hooks/useDialog';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { loadMedDiary, loadMedDiaryDays, saveMedDiary } from '../../services/medicines';

import { ExpandMore, DiaryDay } from '../nutrients/DiaryDay';

import Layout from '../layout/Layout';
import LoaderWrapper from '../common/LoaderWrapper';

import MedicinesSelectDialog from './MedicinesSelectDialog';
import MedicinesDailyList from './MedicinesDailyList';

import {
  MED_ACTIONS,
  dataReducer,
  getSaveMedItems
} 
from './medicinesData';

import '../nutrients/nutrients.css';
import './medicines.css';

const MedicinesTrackPage = () => {
  const abortController = useRef(null);

  const [
    openMedSelectDialog, 
    closeSelectMedDialog, 
    medSelectData
  ] = useDialog(STORE.areas.medicines.dialog);

  const [loadMedDiaryData, loadMedDiaryAsync] = useAsyncAppState(
    STORE.areas.medicines.load,
    loadMedDiary
  );

  const [medDiaryDays, loadMedDiaryDaysAsync] = useAsyncAppState(
    STORE.areas.medicines.loadDates,
    loadMedDiaryDays
  );

  const [saveMedDiaryData, saveMedDiaryAsync] = useAsyncAppState(
    STORE.areas.medicines.save,
    saveMedDiary
  );

  const [expanded, setExpanded] = useState(true);
  const [targetDate, setTargetDate] = useState(moment());

  const confirm = useConfirm();
  const navigate = useNavigate();

  const [openMedDetailsDialog] = useDialog(STORE.areas.medicines.dialogDetails);

  const [data, dataDispatch] = useReducer(dataReducer, {
    medItems: [],
    iteration: 0,
  });

  const onLoadMedDiary = useCallback(async (onDate) => {
    const medItems = await loadMedDiaryAsync(onDate);
    dataDispatch({
      type: MED_ACTIONS.INIT,
      medItems: medItems || [],
    });

  }, [loadMedDiaryAsync, dataDispatch]);

  const onMonthChange = useCallback((onDate) => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    loadMedDiaryDaysAsync({
      controller: abortController.current, 
      onDate
    });
  }, []);

  const onAddMedicine = useCallback(async () => {
    const medItem = await openMedSelectDialog();

    if (medItem) {
      dataDispatch({
        type: MED_ACTIONS.ADD,
        medItem,
      });
    }
  }, [targetDate, openMedSelectDialog, dataDispatch]);

  const onEditMedicine = useCallback(async (medItem, index) => {
    const updatedMedItem = await openMedDetailsDialog({
      medType: medItem.medType,
      medData: medItem.medData,
      quantity: medItem.quantity
    });

    if (updatedMedItem) {
      dataDispatch({
        type: MED_ACTIONS.UPDATE,
        index,
        medItem: updatedMedItem,
      });
    }
  }, [openMedDetailsDialog, dataDispatch]);

  const onDeleteMedicine = useCallback(async (index) => {
    let valid = true;
    try {
      await confirm({
        title: 'Please confirm',
        description: 'Delete Medicine?' 
      });
    }
    catch {
      valid = false;
    }

    if (valid) {
      dataDispatch({
        type: MED_ACTIONS.REMOVE,
        index
      });
   }
  }, [targetDate, confirm, dataDispatch]);

  const onExit = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const onSetTargetDate = useCallback((onDate) => {
    onLoadMedDiary(onDate);
    setTargetDate(onDate);
  }, [setTargetDate]);
  
  useEffect(() => {
    onLoadMedDiary(null);
    onMonthChange(moment().startOf('month'));
  }, [onMonthChange, dataDispatch]);

  useEffect(() => {
    const save = async () => {
      await saveMedDiaryAsync({
        targetDate,
        medItems: getSaveMedItems(data.medItems) 
      });
      
      abortController.current = new AbortController();

      await Promise.all([
        onLoadMedDiary(targetDate),
        loadMedDiaryDaysAsync({
          controller: abortController.current, 
          onDate: moment(targetDate).startOf('month')
        })
      ]);
    };
    
    if (data.iteration > 0) {
      save();
    }
  }, [
    targetDate, 
    data.iteration, 
    data.medItems, 
    saveMedDiaryAsync,
    loadMedDiaryAsync,
    loadMedDiaryDaysAsync,
    onLoadMedDiary
  ]);

  return (
    <Layout>
      <Box className="vertical-flex h-100">
        <h3>
          Track your medicines
        </h3>
        <LoaderWrapper items={[loadMedDiaryData, saveMedDiaryData]} />
        <Box className="vertical-flex-grow">
          <Grid container>
            <Grid item xs={12} lg={3} className="placeholder-cell" />
            <Grid item xs={12} lg={6} className="main-cell">
              <Grid container className="justify-content-center pb-1">
                <Grid item xs={12} md={7} lg={5} className="pb-1">
                  <Button color="primary" size="large"
                    variant="contained"
                    onClick={onAddMedicine}>
                    Add Daily Medicine
                  </Button>
                </Grid>

                <MedicinesDailyList 
                  items={data.medItems} 
                  onEditMedicine={onEditMedicine}
                  onDeleteMedicine={onDeleteMedicine} />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3} className="calendar-cell">
              <div className="d-flex justify-content-between">
                <ExpandMore
                  expanded={expanded}
                  onClick={() => setExpanded(!expanded)}
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </div>

              <Divider className="mb-1" />
              
              <Collapse in={expanded} timeout="auto">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    onMonthChange={onMonthChange}
                    loading={medDiaryDays.loading}
                    value={targetDate} 
                    onChange={onSetTargetDate}
                    slots={{
                      day: DiaryDay,
                    }}
                    slotProps={{
                      day: {
                        diaryDays: medDiaryDays.data || []
                      },
                    }}
                  />
                </LocalizationProvider>
              </Collapse>
            </Grid>
          </Grid>
        </Box>

        <Box className="buttons-footer">
          <Divider />
            <Grid container className="justify-content-center pb-1">
              <Grid item xs={12} md={5} lg={2}>
                <Button color="primary" size="large"
                  variant="contained"
                  fullWidth
                  onClick={onExit}>
                  Back to Home Page
                </Button>
              </Grid>
            </Grid>
        </Box>
      </Box>

      <MedicinesSelectDialog
        handleClose={closeSelectMedDialog}
        visible={medSelectData.visible}
      />
    </Layout>
  );
}

export default MedicinesTrackPage;
