import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Drawer,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MenuOutlinedIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';
import QuizIcon from '@mui/icons-material/Quiz';

import albright from "../../images/Albright.png";
import EditPersonalDialog from '../dialogs/editPersonalDialog';
import { STORE } from '../../store';
import { saveProfile } from '../../services/userProfile';
import { endSession } from '../../services/session';
import { useSignedIn } from '../../hooks/useSignedIn';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';
import "./TopBar.css";

const TopBar = () => {
  const navigate = useNavigate();
  const [profile, saveProfileAsync] = useAsyncAppState(STORE.common.profile, saveProfile);
  
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [signedIn, setSignedIn] = useSignedIn();
  const [openEditPersonalDialog, setOpenEditPersonalDialog] = useState({
    visible: false,
    profile: profile.data
  });

  const showEditPersonalDialog = useCallback(async (isNewOnly) => {
    const shouldOpen = !isNewOnly || (isNewOnly && !profile.data?.userEmail);
    if (shouldOpen) {
      setOpenEditPersonalDialog({
        visible: true,
        profile: profile.data
      });
    }
  }, [profile.data, setOpenEditPersonalDialog]);

  const hideEditPersonalDialog = useCallback(async (profileData) => {
    if (profileData) {
      await saveProfileAsync(profileData);
    }
    setOpenEditPersonalDialog({
      visible: false,
    });
  }, [saveProfileAsync, setOpenEditPersonalDialog]);

  const toggleDrawer = useCallback((visible) => {
    setDrawerVisible(visible);
  }, [setDrawerVisible]);

  useEffect(() => {
    if (signedIn === true) {
      showEditPersonalDialog(true);
    }
  }, [signedIn, showEditPersonalDialog]);

  const logout = useCallback(() => {
    endSession();
    setSignedIn(false);
  }, [setSignedIn]);

  const onLeftMenuClick = useCallback((url) => {
    navigate(url);
    toggleDrawer(true);
  }, [navigate, toggleDrawer]);

  const onLogoClick = useCallback(() => {
    navigate(signedIn ? '/home' : '/');
    toggleDrawer(false);
  }, [signedIn, navigate, toggleDrawer]);

  return (
    <AppBar className="topbar-appBar" position="relative">
      <Toolbar className="topbar-toolbar">
        <Box className="toolbar-group">
          {signedIn && (
            <IconButton
              color="inherit"
              onClick={() => toggleDrawer(true)}
              edge="start"
            >
              <MenuOutlinedIcon />
            </IconButton>
          )}
          <Button onClick={onLogoClick}>
            <img style={{ height: "40px" }} src={albright} alt="Logo" />
          </Button>
        </Box>

        {signedIn && (
          <Box className="toolbar-group">
            <Button
              onClick={() => showEditPersonalDialog(false)}
              type="button"
              className="menu-button"
            >
              Edit Personal Info
            </Button>

            <Divider orientation="vertical" flexItem className="menu-divider" variant="middle"/>

            <Button
              onClick={logout}
              type="button"
              className="menu-button"
              edge="right"
            >
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>

      {signedIn && (
        <Drawer
          anchor="left"
          open={drawerVisible}
          onClose={() => toggleDrawer(false)}
          className="drawer"
        >
          <Toolbar className="topbar-toolbar">
            <Box className="toolbar-group">
              <IconButton
                color="inherit"
                onClick={() => toggleDrawer(false)}
                edge="start"
              >
                <MenuOutlinedIcon />
              </IconButton>
              <Button onClick={onLogoClick}>
                <img style={{ height: "40px" }} src={albright} alt="Logo" />
              </Button>
            </Box>
          </Toolbar>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/home')}>
              <HomeIcon />
              <ListItemText primary="Personal Assistant" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/timeline')}>
              <MoreTimeIcon />
              <ListItemText primary="Symptoms Timeline" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/profiles-map')}>
              <MapIcon />
              <ListItemText primary="Profiles Map" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/survey-result')}>
              <BubbleChartIcon />
              <ListItemText primary="View Survey Result" />
            </ListItemButton>
          </ListItem>                        
          <Divider />                        
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/construction')}>
              <ViewListIcon />
              <ListItemText primary="View My Health History" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              className="left-menu-button"
              onClick={() => onLeftMenuClick('/construction')}>
              <QuizIcon />
              <ListItemText primary="View Test Results" />
            </ListItemButton>
          </ListItem>
          <Divider />
        </Drawer>
      )}

      <EditPersonalDialog
        visible={openEditPersonalDialog.visible}
        profile={openEditPersonalDialog.profile}
        handleSignUpClose={hideEditPersonalDialog}
      />
    </AppBar>
  );
};

export default TopBar;
