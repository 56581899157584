import axios from 'axios';

export const detectLocation = async () => {
  try {
    const response = await axios.get('http://ip-api.com/json');
    return response.data;
  } catch (error) {
    console.error(`Error retrieving location: ${error.message}`);
  }
  return null;
};

export const loadProfile = async (email) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/profile${email ? ('?email=' + email) : ''}`);
  return response.data;
};

export const saveProfile = async (profileData) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/profile`, profileData);
  return response.data;
};

export const updateProfile = async ({ id, profileData }) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/server/profile/${id}`, profileData);
  return response.data;
};

export const findProfiles = async (values) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/server/profiles`, values);
  return response.data;
};
