import { useState, useCallback, useMemo } from 'react';
import { Button, CheckPicker, Modal } from 'rsuite';
import moment from 'moment';
import './indicator.scss';

import LoaderWrapper from '../../../../components/common/LoaderWrapper';
import { calcExposures, calcDistribution, loadFileData } from '../../../../services/medReport';
import Indicator from './Indicator';
import classNames from 'classnames';

const getItems = (items, onDetails) => {
  if (!items.length) {
    return <div className="title-help-large px-2">No Analytes found</div>;
  }

  return items.map((item) => (
    <div key={item.id} className="item">
      <h1>
        {item.name}
      </h1>

      <h2>
        {item.source ? <a href={`#${item.source}`} onClick={(e) => onDetails(e, item)}>Result</a> : 'Result'} | 
        <b>{moment(item.date).format('MMM DD, YYYY')}</b>
      </h2>

      <Indicator 
        className="mt-5"
        step={item.step}
        range={item.range} 
        value={item.value}
        valueUnit={item.units}
        valid={item.valid}
        normalRange={item.normal}
      />
    </div>
  ))
};

const getFilterSelected = (dates, samples, groups) => {
  const anySelected = dates.length || samples.length || groups.length;
  const dateValues = dates.map((date) => <span key={date}>{moment(date).format('MMM DD, YYYY')}; </span>);
  const sampleValues = samples.map((sample) => <span key={sample}>{sample.name}; </span>);
  const groupValues = groups.map((group) => <span key={group}>{group}; </span>);
  return (
    <div className="title-help-large d-flex">
      <span className="px-2">Filter Selected:</span>
      <i>
        {anySelected ? <>
          {dateValues.length || sampleValues.length ? <>{dateValues} {sampleValues}</> : 'No dates, sample types selected'}
          {groups.length > 0 && <><br />{groupValues}</>}
        </> : 'None'}
      </i>
    </div>  
  )
};

const MAX_VISIBLE = 8;

const Exposures = ({ data }) => {
  const { exposures } = data;
  const defaultDates = useMemo(() => [exposures.dates[0]], [exposures.dates]);
  const defaultSamples = useMemo(() => [exposures.samples[0]], [exposures.samples]);

  const [items, setItems] = useState(calcExposures(exposures.items, defaultDates, defaultSamples));
  const [allVisible, setAllVisible] = useState(true);
  const [currentDates, setCurrentDates] = useState(defaultDates);  
  const [currentSamples, setCurrentSamples] = useState(defaultSamples);  
  const [currentGroups, setCurrentGroups] = useState([]);  
  const [details, setDetails] = useState(null);
  const [distribution, setDistribution] = useState(null);

  // useEffect(() => {
  //   if (exposures?.items) {
  //     let out = '';
  //     let all = {};
  //     exposures.items.forEach((item) => {
  //       if (!all[item.substance]) {
  //         all[item.substance] = 1;
  //         out += `${item.substance}\r\n`;
  //       }
  //     });
  //     console.log(out);
  //   }
  // }, [exposures]);

  const onExpand = useCallback((showAll) => {
    setAllVisible(showAll);
  }, [setAllVisible]);

  const onDate = useCallback((dates) => {
    setCurrentDates(dates);
    setItems(calcExposures(exposures.items, dates, currentSamples, currentGroups));
  }, [exposures, currentSamples, currentGroups, setItems, setCurrentDates]);

  const onSample = useCallback((samples) => {
    setCurrentSamples(samples);
    setItems(calcExposures(exposures.items, currentDates, samples, currentGroups));
  }, [exposures, currentDates, currentGroups, setItems, setCurrentSamples]);

  const onGroups = useCallback((groups) => {
    setCurrentGroups(groups);
    setItems(calcExposures(exposures.items, currentDates, currentSamples, groups));
  }, [exposures, currentDates, currentSamples, setItems, setCurrentGroups]);

  const onDetails = useCallback(async (e, item) => {
    e.preventDefault();
    
    const data = await loadFileData(item.source);
    if (data?.length) {
      const lines = data.split('\n');
      
      setDetails({
        item,
        lines
      });
    }
  }, [setDetails]);

  const onDistribution = useCallback(async () => {
    const data = calcDistribution(items);
    setDistribution(data);
  }, [items, setDistribution]);

  const sampleTypes = useMemo(() => {
    return exposures.samples.map((sample) => ({ label: sample.name, value: sample }));
  }, [exposures.samples]);

  const sampleDates = useMemo(() => {
    return exposures.dates.map((date) => ({ label: moment(date).format('MMM DD, YYYY'), value: date }));
  }, [exposures.dates]);

  const renderElements = (range) => {
    return (
      <>
        {range.map(({ name, valid }, index) => <div key={name + index} className={`${valid}-range`}>{name}</div>)}
        {!range.length && '-'}
      </>
    )
  };

  return (
    <LoaderWrapper show>
      <h2 className="title-section">
        Analytes
      </h2>

      <div className="row">
        <div className="col-6 col-xl-3 pb-2">
          <div className="text-nowrap m-2">Sample Date:</div>
          <CheckPicker data={sampleDates}
            value={currentDates}
            onChange={onDate} 
            searchable={false} 
            cleanable={false}
            className="ckp-gadget-2" />
        </div>  

        <div className="col-6 col-xl-3 pb-2">
          <div className="m-2 text-nowrap">Sample Type:</div>
          <CheckPicker data={sampleTypes} 
            value={currentSamples}
            onChange={onSample} 
            searchable={false} 
            cleanable={false}
            className="ckp-gadget-2" />
        </div>

        <div className="col-6 col-xl-4 pb-2">
          <div className="m-2 text-nowrap">Analyte Group:</div>
          <CheckPicker data={exposures.groups} 
            value={currentGroups}
            onChange={onGroups}
            searchable={false}
            className="ckp-gadget-2" />
        </div>

        <div className="col-6 col-xl-2 pb-2 d-flex align-items-end">
          <Button 
            appearance="default" 
            className="btn-gadget-2 w-100" 
            onClick={onDistribution}>
            Analytes Distribution
          </Button>
        </div>
      </div>
      {getFilterSelected(currentDates, currentSamples, currentGroups)}
      <hr />

      <div className="exposures">
        {getItems(allVisible ? items : items.splice(0, MAX_VISIBLE), onDetails)}
      </div>
      
      {items.length > 0 && <Button 
        appearance="default" 
        className="btn-gadget-2 mt-3" 
        onClick={() => onExpand(!allVisible)}>
        {allVisible ? 'Hide all' : 'View all'}
      </Button>}

      <Modal className="round-modal"
        size="90vw"
        open={Boolean(details)} 
        onClose={() => setDetails(null)}>
        <Modal.Header />
        {details && (
          <Modal.Body className="green">
            <h3>{details.item.name}</h3>
            <h2>
              File: {details.item.source}
            </h2>
            <hr></hr>
            {details.lines.map((line, index) => 
              <pre className={classNames('console', line.indexOf(details.item.name) >= 0 && 'selected')} key={index}>{line}</pre>)}
          </Modal.Body>)}
      </Modal>

      <Modal className="round-modal"
        size="md"
        open={Boolean(distribution)} 
        onClose={() => setDistribution(null)}>
        <Modal.Header />
        {distribution && (
          <Modal.Body className="green">
            <h3>
              Distribution
            </h3>
            <hr />
            <div className="row">
              <div className="col-6">
                Upper Bound Outliers
              </div>
              <div className="col-6">
                {renderElements(distribution.range200)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                76-100%
              </div>
              <div className="col-6">
                {renderElements(distribution.range100)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                51-75%
              </div>
              <div className="col-6">
                {renderElements(distribution.range75)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                26-50%
              </div>
              <div className="col-6">
                {renderElements(distribution.range50)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                0-25%
              </div>
              <div className="col-6">
                {renderElements(distribution.range25)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                Lower Bound Outliers
              </div>
              <div className="col-6">
                {renderElements(distribution.range00)}
              </div>
            </div>
            
          </Modal.Body>)}
      </Modal>
      
    </LoaderWrapper>
  );
};

export default Exposures;