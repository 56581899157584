import axios from 'axios';

export const uploadResultFiles = async (params) => {
  const { files, isUnique } = params;
  const filesData = [];
  for (let file of files) {
    const formData = new FormData();
    formData.append('document', file);
    
    const url = `${process.env.REACT_APP_API_URL}/server/documents/upload?unique=${isUnique ? 'true' : 'false'}`;
    const result = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    filesData.push({
      file,
      data: result.data
    });
  }

  return filesData;
};

export const loadFiles = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/documents`);
  return response.data;
};

export const deleteFile = async (id) => {
  await axios.delete(`${process.env.REACT_APP_API_URL}/server/documents/${id}`);
};

export const downloadFile = async (params) => {
  const { fileId, fileName } = params;
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/server/documents/${fileId}`, { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }, 0);
};