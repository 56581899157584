import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { 
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  BtnUndo,
  BtnLink,
  Editor,
  EditorProvider,
  Toolbar
} from 'react-simple-wysiwyg';

import { Animation, Button } from 'rsuite';

import UploadDocsForm from '../shared/uploader/UploadDocsForm';

const RtfEditor = ({ value, disabled, onSend }) => {
  const [text, setText] = useState(value);
  const [attach, setAttach] = useState(false);
  const [files, setFiles] = useState(null);
  const editor = useRef();

  useEffect(() => {
    if (editor.current) {
      editor.current.focus();
   }
  }, []);

  const onSendMessage = useCallback((text, uploadFiles) => {
    if (text.length) {
      setText(''); 
      setAttach(false);
      onSend(text, uploadFiles);
    }
  }, [onSend, setText, setAttach]);

  return (
    <div className={classNames('rft-editor', disabled && 'disabled')}>
      <EditorProvider>
        <Editor ref={editor}
          value={text} 
          disabled={disabled}
          onChange={(e) => setText(e.target.value)} 
          placeholder="Type here..." />
        <hr className="mx-2"/>
        <Toolbar>
          <div className="w-100">
            <div className="d-flex justify-content-between">
              <div>
                <BtnUndo disabled={disabled} />
                <BtnBold disabled={disabled} />
                <BtnItalic disabled={disabled} />
                <BtnUnderline disabled={disabled} />
                <BtnBulletList disabled={disabled} />
                <BtnNumberedList disabled={disabled} />
                <BtnLink disabled={disabled} />
                <Button 
                  appearance="subtle" 
                  className="rtf-attach rsw-btn" 
                  onClick={() => setAttach(!attach)}>
                  <span className="material-icons">attach_file</span>
                </Button>
              </div>
              <Button
                disabled={disabled}
                appearance="default" 
                className="btn-gadget-2 btn-send" 
                onClick={() => onSendMessage(text, files)}>
                {Boolean(value) ? 'Edit & Submit': 'Submit' }
              </Button>
            </div>

            <Animation.Collapse in={attach}>
              <div className="pt-2">
                {attach && <UploadDocsForm onSelectFiles={setFiles} />}
              </div>
            </Animation.Collapse>
          </div>
        </Toolbar>
      </EditorProvider>
    </div>
  )
};

export default RtfEditor;
