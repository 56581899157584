import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { STORE } from '../../../store';
import { updateProfile } from '../../../services/userProfile';
import { loadReportData } from '../../../services/medReport';
import { useAsyncAppState  } from '../../../hooks/useAsyncAppState';
import LoaderWrapper from '../../../components/common/LoaderWrapper';

import Layout from '../../layout/Layout';
import ReportHeader from './ReportHeader';
import PatientDetails from './PatientDetails';
import Symptoms from './SymptomsSwiper/SymptomsSwiper';
import Genetics from './Genetics';
import Medication from './Medication';
import Supplements from './Supplements';
import Nutrition from './Nutrition';
import Exposures from './Exposures/Exposures';
import Metabolism from './Metabolism/Metabolism';
import './report.scss';

const MedicalReportPage = () => {
  const { code } = useParams();
  const [customize, setCustomize] = useState({});

  const [reportData, loadReportDataAsync] = useAsyncAppState(
    STORE.areas.medReport.load,
    loadReportData
  );

  const [profileUpdating, updateProfileAsync] = useAsyncAppState(
    STORE.areas.profile.update, updateProfile
  );
  
  const onSaveProfileDone = useCallback((customize) => {
    console.log(customize);
    setCustomize(customize);
  }, []);

  useEffect(() => {
    const load = async () => {
      const data = await loadReportDataAsync(code);
      setCustomize(data?.patient?.medReportCustomize || {});
    };
    load();
  }, [code, loadReportDataAsync]);

  return (
    <Layout className="rs-suite">
      <LoaderWrapper items={[reportData]}>
        <LoaderWrapper items={[profileUpdating]} show>
          <ReportHeader data={reportData.data} 
            onSaveProfile={updateProfileAsync} 
            onSaveProfileDone={onSaveProfileDone} />
          <PatientDetails data={reportData.data} />
          {customize.symptoms?.visible && <Symptoms data={reportData.data} />}
          {customize.exposures?.visible && <Exposures data={reportData.data} />}
          {customize.metabolism?.visible && <Metabolism data={reportData.data} />}
          {customize.medication?.visible && <Medication data={reportData.data} />}
          {customize.supplements?.visible && <Supplements data={reportData.data} />}
          {customize.genetics?.visible && <Genetics data={reportData.data} />}
          {customize.nutrition?.visible && <Nutrition data={reportData.data} />}
        </LoaderWrapper>  
      </LoaderWrapper>
    </Layout>
  )
};

export default MedicalReportPage;