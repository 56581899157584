import React, { useCallback, useState } from 'react';

import TreeNode from './TreeNode';
import { 
  getInitialRequests, 
  getOnActionRequests,
  onNewNode, 
  onDeleteNode, 
} from './treeData';

import './treeView.scss';

const Tree = (props) => {
  const { 
    node, 
    onOpenUrl, 
    onOpenExternal,
    onAddExternal,
    onDeleteExternal,
  } = props;

  const [requests, setRequests] = useState(getInitialRequests(node));
  
  const onAction = useCallback((action, targetNode) => {
    const newRequests = getOnActionRequests(action, targetNode, {
      onNewNode: () => onNewNode(targetNode, node),
      onDeleteNode: async () => onDeleteNode(targetNode),
      onOpenLink: () => onOpenUrl(targetNode.link),
      onAddExternal: () => onAddExternal(targetNode),
      onOpenExternal: () => onOpenExternal(targetNode.externalId),
      onDeleteExternal: () => onDeleteExternal(targetNode),
      onSave: () => {}
    });

    setRequests(newRequests);
  }, [
    node, 
    onOpenUrl, 
    onAddExternal,
    onOpenExternal, 
    onDeleteExternal,
    setRequests
  ]);

  return node && (
    <TreeNode 
      node={node}
      requests={requests}
      onAction={onAction} />
  );  
};


export default Tree;