export const ACTION_UI = {
  menu: 'menu',
  button: 'button'
};

export const ACTION = {
  view: { name: 'view' },
  openLink: { name: 'open-link', text: 'Open Link', ui: ACTION_UI.button, icon: 'launch' },
  openExternal: { name: 'open-ext', text: 'Open External', ui: ACTION_UI.button, icon: 'share' },
  editText: { name: 'edit-text', text: 'Edit Text', ui: ACTION_UI.menu, icon: 'edit_note' },
  addLink: { name: 'add-link', text: 'Add Link', ui: ACTION_UI.menu, icon: 'link' },
  editLink: { name: 'edit-link', text: 'Edit Link', ui: ACTION_UI.menu, icon: 'link' },
  addExternal: { name: 'add-ext', text: 'Link External', ui: ACTION_UI.menu, icon: 'share' },
  deleteExternal: { name: 'delete-ext', text: 'Unlink External', ui: ACTION_UI.menu, icon: 'clear' },
  addNode: { name: 'add-node', text: 'Add Node', ui: ACTION_UI.menu, icon: 'create_new_folder' },
  deleteNode: { name: 'delete-node', text: 'Delete Node', ui: ACTION_UI.menu, icon: 'folder_delete' },
  save: { name: 'save', text: 'Save', ui: ACTION_UI.button, icon: 'save' },
  cancel: { name: 'cancel', text: 'Cancel', ui: ACTION_UI.button, icon: 'redo' },
};

export const EDITOR_ACTIONS = [
  ACTION.editText.name, 
  ACTION.editLink.name, 
  ACTION.addLink.name
];

const getMaxId = (rootNode) => {
  let result = parseInt(rootNode.id.replace(/id_/i, ''));

  if (rootNode.children) {
    rootNode.children.forEach((childNode) => {
      const childId = getMaxId(childNode);
      if (childId > result) {
        result = childId;
      }
    });
  }

  return result;
};

/*
  requests: {
    node,
    action,
    expanded,
    disabled
  }[]
*/

export const onNewNode = (targetNode, rootNode) => {
  const newId = getMaxId(rootNode) + 1;
  const newNode = {
    id: `ID_${newId}`,
    parent: targetNode,
    name: 'New Node',
    link: null
  };

  targetNode.children = [...targetNode.children || [], newNode];
  return newNode;
};

export const onDeleteNode = (targetNode) => {
  targetNode.parent.children = 
    targetNode.parent.children.filter((deleteNode) => deleteNode !== targetNode);
};

export const getOnActionRequests = (action, targetNode, onAction) => {
  let result = [{ action: ACTION.view }];
  
  if (EDITOR_ACTIONS.indexOf(action.name) >= 0) {
    result = [
      { disabled: true },
      { node: targetNode, action, disabled: false }
    ]
  }
  else if (action.name === ACTION.addNode.name) {
    const newNode = onAction.onNewNode();

    result = [
      { disabled: true },
      { node: targetNode, action: ACTION.view, expanded: true },
      { node: newNode, action: ACTION.editText, disabled: false }
    ];
  }
  else if (action.name === ACTION.deleteNode.name) {
    onAction.onDeleteNode();
  }
  else if (action.name === ACTION.save.name) {
    onAction.onSave();
  }
  else if (action.name === ACTION.openLink.name) {
    onAction.onOpenLink();
  }
  else if (action.name === ACTION.addExternal.name) {
    onAction.onAddExternal();
  }
  else if (action.name === ACTION.openExternal.name) {
    onAction.onOpenExternal();
  }
  else if (action.name === ACTION.deleteExternal.name) {
    onAction.onDeleteExternal();
  }

  return result;
};

// Tree
export const getInitialRequests = (rootNode) => [
  { node: rootNode, expanded: true }
];

// TreeNode
export const getExpanded = (targetNode, requests) => {
  let request = requests.find((request) => request.node === targetNode);
  if (request && typeof(request.expanded) !== 'undefined') {
    return request.expanded;
  }

  request = requests.find((request) => !request.node);
  if (request && typeof(request.expanded) !== 'undefined') {
    return request.expanded;
  }

  return null;
};

// TreeNodeEditor
export const getAction = (targetNode, requests) => {
  let request = requests.find((request) => request.node === targetNode);
  if (request && request.action) {
    return request.action;
  }

  request = requests.find((request) => !request.node);
  if (request && request.action) {
    return request.action;
  }

  return ACTION.view;
};

// TreeNodeEditor
export const getDisabled = (targetNode, requests) => {
  let request = requests.find((request) => request.node === targetNode);
  if (request && typeof(request.disabled) !== 'undefined') {
    return request.disabled;
  }

  request = requests.find((request) => !request.node);
  if (request && typeof(request.disabled) !== 'undefined') {
    return request.disabled;
  }

  return false;
};

// TreeNodeButtons
export const getActionControls = (targetNode, action, disabled) => {
  if (disabled) {
    return null;
  }

  if (EDITOR_ACTIONS.indexOf(action.name) >= 0) {
    return [ACTION.save, ACTION.cancel];
  }

  let resultActions = [ACTION.addNode];
  if (targetNode.parent) {
    resultActions.push(ACTION.deleteNode);
  }

  if (targetNode.link?.length) {
    resultActions.push(ACTION.openLink);
  }

  if (targetNode.externalId) {
    resultActions.push(ACTION.openExternal);
  }

  resultActions.push(ACTION.editText);

  if (targetNode.link?.length) {
    resultActions.push(ACTION.editLink);    
  }
  else {
    resultActions.push(ACTION.addLink);
  }

  if (targetNode.externalId) {
    resultActions.push(ACTION.deleteExternal);
  }
  else {
    resultActions.push(ACTION.addExternal);
  }

  return resultActions;
};