import { useCallback, useState } from 'react';
import { 
  Button,
  Checkbox,
  IconButton, 
  ButtonToolbar,
  Modal
} from 'rsuite';

const ReportHeader = ({ data, onSaveProfile, onSaveProfileDone }) => {
  const { operator, patient } = data;
  const [ customize, setCustomize] = useState(patient.medReportCustomize);
  const [ showCustomize, setShowCustomize ] = useState(false);

  const onProfileSave = useCallback(async () => {
    const { _id, ...rest } = patient;
    await onSaveProfile({ 
      id: _id, 
      profileData: { ...rest, medReportCustomize: customize } 
    });
    onSaveProfileDone(customize);
    setShowCustomize(false);
  }, [customize, patient, onSaveProfile, onSaveProfileDone, setShowCustomize]);

  return (
    <div>
      <div className="title-help-large text-uppercase d-flex align-items-center mb-2">
        <span>
          <b>{patient.fullName}</b> medical report
        </span>
        <ButtonToolbar className="ms-2">
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">print</span>} />
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">email</span>} />
          <IconButton circle appearance="default" size="sm" icon={<span className="material-icons">download</span>} />
          {customize && (
            <IconButton circle 
              appearance="default"
              title="Customize Report" 
              size="sm" 
              icon={<span className="material-icons">dashboard_customize</span>} 
              onClick={() => setShowCustomize(true)} />
          )}
          </ButtonToolbar>
      </div>

      <h1>
        Hi, Dr. {operator.fullName}, here is your overview of {patient.fullName} medical report
      </h1>

      <p>
        Hello Dr. {operator.fullName}, here are the details about <b>{patient.fullName}</b> medical details outlining their symptoms, 
        latest clinical studies, genetics, current medications, and overall health
      </p>

      <div className="gradient-divider mb-4"></div>

      <Modal className="round-modal"
        open={showCustomize} 
        onClose={() => setShowCustomize(false)}>
        <Modal.Header />
        
        <Modal.Body className="green">
          <h3><span className="material-icons">dashboard_customize</span>CUSTOMIZE REPORT</h3>
          <h4>Sections visibilty</h4>
          <legend>
            {Object.keys(customize).map((section) => 
              <div key={section}>
                <Checkbox
                  checked={customize[section].visible} 
                  onClick={(e) => setCustomize({...customize, [section]: {...customize[section], visible: e.target.checked }})}>
                    {customize[section].label}
                </Checkbox>
              </div>
            )}
          </legend>
          <div>
            <Button
              appearance="default" 
              className="btn-gadget-2 mt-3"
              onClick={onProfileSave}>
              Save Settings
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReportHeader;