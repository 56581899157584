import axios from 'axios';

import { calcWeight } from '../components/nutrients/nutrientsData';
import { ELEMENT_GROUPS } from '../constants/exposures';

const capitalize = (word) => {
  if (!word?.length) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);;
};

const normalizeVariants = (variants) => {
  const values = variants.split(',')
    .map((word) => word.trim())
    .filter((word) => word.length);

  const result = [];
  values.forEach((variant) => {
    const value = variant
      .split('_')
      .filter((word) => word.length && word !== 'variant')
      .map((word) => capitalize(word))
      .join(' ');

    result.push(value);  
  });

  return result.join('; ');
};

const normalizeSignificance = (significants) => {
  const values = significants.split(',')
    .map((word) => word.trim())
    .filter((word) => word.length);
  
  return values.length === 1 ? capitalize(values[0]) : 'Conflicting';
};

const normailzeGenetics = (data) => {
  const records = data.genetics?.geneMap;
  if (records?.length) {
    records.forEach((record) => {
      record.variants = normalizeVariants(record.variants);
      record.significants = normalizeSignificance(record.significants);
      record.geneName = capitalize(record.geneName);
    });
  }
};

const calcNutrients = (data) => {
  const foodAllItems = data.diary;
  if (foodAllItems) {
    Object.keys(foodAllItems).forEach((targetDate) => {
      const foodItems = foodAllItems[targetDate];
      foodItems.forEach((foodItem) => {
        foodItem.weight = calcWeight(
          foodItem.foodData, 
          foodItem.foodType, 
          foodItem.portionId, 
          foodItem.quantity);
        
        if (foodItem.portionId && foodItem.foodData?.portions) {
          foodItem.portion = foodItem.foodData.portions
            .find((portion) => portion._id === foodItem.portionId);
        }
      });
    });
  }
};

export const calcExposures = (rawItems, onDates, onSamples, onGroup) => {
  /*
  name: 'Aluminium',
  date: '2025-01-05',
  value: 3,
  normal: [1, 9]
  */
  const result = [];

  const calcPower = (max) => {
    let delta = max;
    let power = 1;
    while (delta < 1 || delta > 10) {
      if (delta < 1) {
        delta = delta * 10;
        power = power * 10;
      }
      else {
        delta = delta / 10;
        power = power / 10;
      }
    }
    return power;
  };

  const calcStepRange = (min, max, value) => {
    const valueMax = Math.max(max, value);
    const valueMin = Math.min(min, value);
    const power = calcPower(valueMax);
    const rangePower = 1 / power;

    let rangeMin = Math.round(10000 * Math.floor(valueMin / rangePower) * rangePower) / 10000;
    if (rangeMin < 0) {
      rangeMin = 0;
    }

    const rangeMax = Math.round(10000 * Math.ceil(valueMax / rangePower) * rangePower) / 10000;
    const step = rangePower;

    return [step, [rangeMin, rangeMax]];
  };

  const getGroupMap = (groups) => {
    let groupMap = [];
    groups.forEach((group) => {
      const elementMap = ELEMENT_GROUPS[group].map((elementName) => ({
        group,
        name: elementName.toLowerCase().replace(/[-* \(\)]/g, '')
      }));
      groupMap = groupMap.concat(elementMap);
    });

    return groupMap;
  };

  const isInGroup = (name, inGroupMap) => {
    if (!inGroupMap) return true;

    const maskName = name.toLowerCase().replace(/[-* \(\)]/g, '');
    return inGroupMap.some((pair) => maskName.startsWith(pair.name));
  };
  
  const groupMap = onGroup?.length ? getGroupMap(onGroup) : null;
  const sampleLetters = onSamples.map((sample) => sample.letter);
  const items = (rawItems || [])
    .filter((item) => 
      onDates.indexOf(item.date) >= 0 && 
      sampleLetters.indexOf(item.sourceSample?.letter) >= 0 &&
      isInGroup(item.substance, groupMap))
    .sort((item) => item.isValid ? 1 : -1);
  
  const metalsGroupMap = getGroupMap(['Metals - Toxic', 'Metals - Inert', 'Metals - Essential']);
  items.forEach((item) => {
    if (item.intervalMax > item.intervalMin) {
      const [step, range] = calcStepRange(item.intervalMin, item.intervalMax, item.result);

      let validType = 'valid';
      if (!item.isValid) {
        if (isInGroup(item.substance, metalsGroupMap) && item.result < item.intervalMin) {
          validType = 'partial';
        }
        else {
          validType = 'invalid';
        }
      }

      result.push({
        id: item.id,
        date: item.date,
        name: item.substance,
        units: item.units,
        value: item.result,
        type: item.chemType,
        step,
        range, 
        source: item.source,
        valid: validType,
        normal: [item.intervalMin, item.intervalMax]
      })
    }
  });
  
  return result;
};

export const calcDistribution = (items) => {
  const results = {
    range00: [],
    range25: [],
    range50: [],
    range75: [],
    range100: [],
    range200: []
  };
  
  items.forEach((item) => {
    const name = item.name;
    const value = item.value;
    const valid = item.valid;
    const from = item.normal[0];
    const to = item.normal[1];
    if (typeof(value) !== 'undefined' && typeof(from) !== 'undefined' && typeof(to) !== 'undefined' && from < to) {
      if (value < from) {
        results.range00.push({ name, valid });
      }
      else if (value > to) {
        results.range200.push({ name, valid });
      }
      else {
        const delta = (value - from) / (to - from);
        if (delta <= 0.25) {
          results.range25.push({ name, valid });
        }
        else if (delta <= 0.5) {
          results.range50.push({ name, valid });
        }
        else if (delta <= 0.75) {
          results.range75.push({ name, valid });
        }
        else if (delta <= 1) {
          results.range100.push({ name, valid });
        }
      }
    }
  });

  results.range00 = results.range00.sort();
  results.range25 = results.range25.sort();
  results.range50 = results.range50.sort();
  results.range75 = results.range75.sort();
  results.range100 = results.range100.sort();

  return results;
};

const addMetabolites = (data) => {
  data.metabolites = [
    {
      id: 1,
      name: 'Glycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 40
    },
    {
      id: 2,
      name: 'N-acetylglycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 20
    },
    {
      id: 3,
      name: 'Sarcosine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 80
    },
    {
      id: 4,
      name: 'Dimethylglycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 10
    },
    {
      id: 5,
      name: 'Glycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 40
    },
    {
      id: 6,
      name: 'N-acetylglycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 20
    },
    {
      id: 7,
      name: 'Sarcosine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 80
    },
    {
      id: 8,
      name: 'Dimethylglycine',
      pathway: 'Glycine, Serine and Threonine Metabolism',
      geneName: 'DDC (dopa decarboxylase)',
      value: 10
    },
  ];
};

const addExposures = (data) => {
  data.exposures.groups = Object.keys(ELEMENT_GROUPS).map((item) => ({ label: item, value: item }));
};

export const loadReportData = async (patientId) => {
  const url = `${process.env.REACT_APP_API_URL}/medReport/${patientId}`;
  const response = await axios.get(url);
  normailzeGenetics(response.data);
  calcNutrients(response.data);
  addMetabolites(response.data);
  addExposures(response.data);
  return response.data;
};

export const loadSymptomsData = async (args) => {
  const { patientEmail, options } = args;
  const url = `${process.env.REACT_APP_API_URL}/medReport/symptoms/${patientEmail}`;
  const response = await axios.put(url, options);
  return response.data;
};

export const loadFileData = async (fileName) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/medReport/files/${fileName}`);
    return response.data;
  }
  catch {
    return null;
  }
};