import { useState, useCallback, useMemo } from 'react';
import { Button, CheckPicker, Dropdown, Modal, SelectPicker } from 'rsuite';
import moment from 'moment';
import './indicator.scss';

import LoaderWrapper from '../../../../components/common/LoaderWrapper';
import { calcExposures, calcDistribution, loadFileData } from '../../../../services/medReport';
import Indicator from './Indicator';
import classNames from 'classnames';

const getItems = (items, onDetails) => {
  if (!items.length) {
    return <>No exposures found</>
  }

  return items.map((item) => (
    <div key={item.id} className="item">
      <h1>
        {item.name}
      </h1>

      <h2>
        {item.source ? <a href={`#${item.source}`} onClick={(e) => onDetails(e, item)}>Result</a> : 'Result'} | 
        <b>{moment(item.date).format('MMM DD, YYYY')}</b>
      </h2>

      <Indicator 
        className="mt-5"
        step={item.step}
        range={item.range} 
        value={item.value}
        valueUnit={item.units}
        normalRange={item.normal}
      />
    </div>
  ))
};

const Exposures = ({ data }) => {
  const { exposures } = data;
  const defaultDate = useMemo(() => exposures.dates[0], [exposures.dates]);
  const defaultSample = useMemo(() => exposures.samples[0], [exposures.samples]);

  const [items, setItems] = useState(calcExposures(exposures.items, defaultDate, defaultSample));
  const [allVisible, setAllVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(defaultDate);  
  const [currentSample, setCurrentSample] = useState(defaultSample);  
  const [currentGroups, setCurrentGroups] = useState([]);  
  const [details, setDetails] = useState(null);
  const [distribution, setDistribution] = useState(null);

  // useEffect(() => {
  //   if (exposures?.items) {
  //     let out = '';
  //     let all = {};
  //     exposures.items.forEach((item) => {
  //       if (!all[item.substance]) {
  //         all[item.substance] = 1;
  //         out += `${item.substance}\r\n`;
  //       }
  //     });
  //     console.log(out);
  //   }
  // }, [exposures]);

  const onExpand = useCallback((showAll) => {
    setAllVisible(showAll);
  }, [setAllVisible]);

  const onDate = useCallback((date) => {
    setCurrentDate(date);
    setItems(calcExposures(exposures.items, date, currentSample, currentGroups));
  }, [exposures, currentSample, currentGroups, setItems, setCurrentDate]);

  const onSample = useCallback((sample) => {
    setCurrentSample(sample);
    setItems(calcExposures(exposures.items, currentDate, sample, currentGroups));
  }, [exposures, currentDate, currentGroups, setItems, setCurrentSample]);

  const onGroups = useCallback((groups) => {
    setCurrentGroups(groups);
    setItems(calcExposures(exposures.items, currentDate, currentSample, groups));
  }, [exposures, currentDate, currentSample, setItems, setCurrentGroups]);

  const onDetails = useCallback(async (e, item) => {
    e.preventDefault();
    
    const data = await loadFileData(item.source);
    if (data?.length) {
      const lines = data.split('\n');
      
      setDetails({
        item,
        lines
      });
    }
  }, [setDetails]);

  const onDistribution = useCallback(async () => {
    const data = calcDistribution(items);
    setDistribution(data);
  }, [items, setDistribution]);

  const sampleTypes = useMemo(() => {
    return exposures.samples.map((sample) => ({ label: sample.name, value: sample }));
  }, [exposures.samples]);

  const sampleDates = useMemo(() => {
    return exposures.dates.map((date) => ({ label: moment(date).format('MMM DD, YYYY'), value: date }));
  }, [exposures.dates]);

  const renderElements = (range) => {
    return (
      <>
        {range.map((name, index) => <div key={name + index}>{name}</div>)}
        {!range.length && '-'}
      </>
    )
  };

  return (
    <LoaderWrapper show>
      <h2 className="title-section">
        Analytes
      </h2>

      <div className="row">
        <div className="col-12 col-md-2 pb-2">
          <div className="text-nowrap me-2">Sample Date:</div>
          <SelectPicker data={sampleDates}
            value={currentDate}
            onChange={onDate} 
            searchable={false} 
            cleanable={false}
            className="ckp-gadget-2" />
        </div>  

        <div className="col-12 col-md-2 pb-2">
          <div className="me-2 text-nowrap">Sample Type:</div>
          <SelectPicker data={sampleTypes} 
            value={currentSample}
            onChange={onSample} 
            searchable={false} 
            cleanable={false}
            className="ckp-gadget-2" />
        </div>

        <div className="col-12 col-md-5 pb-2">
          <div className="me-2 text-nowrap">Analyte Group:</div>
          <CheckPicker data={exposures.groups} 
            value={currentGroups}
            onChange={onGroups}
            searchable={false}
            className="ckp-gadget-2" />
        </div>

        <div className="col-12 col-md-3 pb-2 d-flex align-items-end">
          <Button 
            appearance="default" 
            className="btn-gadget-2" 
            onClick={onDistribution}>
            Analytes Distribution
          </Button>
        </div>
      </div>
      <hr />

      <div className="exposures">
        {getItems(items, onDetails)}
      </div>
      
      {/* <Button 
        appearance="default" 
        className="btn-gadget-2 mt-3" 
        onClick={() => onExpand(!allVisible)}>
        {allVisible ? 'Hide all' : 'View all'}
      </Button> */}

      <Modal className="round-modal"
        size="90vw"
        open={Boolean(details)} 
        onClose={() => setDetails(null)}>
        <Modal.Header />
        {details && (
          <Modal.Body className="green">
            <h3>{details.item.name}</h3>
            <h2>
              File: {details.item.source}
            </h2>
            <hr></hr>
            {details.lines.map((line, index) => 
              <pre className={classNames('console', line.indexOf(details.item.name) >= 0 && 'selected')} key={index}>{line}</pre>)}
          </Modal.Body>)}
      </Modal>

      <Modal className="round-modal"
        size="md"
        open={Boolean(distribution)} 
        onClose={() => setDistribution(null)}>
        <Modal.Header />
        {distribution && (
          <Modal.Body className="green">
            <h3>
              Distribution
            </h3>
            <hr />
            <div className="row">
              <div className="col-6 invalid-range">
                Upper Bound Outliers
              </div>
              <div className="col-6 invalid-range">
                {renderElements(distribution.range200)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                76-100%
              </div>
              <div className="col-6">
                {renderElements(distribution.range100)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6 valid-range">
                51-75%
              </div>
              <div className="col-6 valid-range">
                {renderElements(distribution.range75)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6 valid-range">
                26-50%
              </div>
              <div className="col-6 valid-range">
                {renderElements(distribution.range50)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6">
                0-25%
              </div>
              <div className="col-6">
                {renderElements(distribution.range25)}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-6 invalid-range">
                Lower Bound Outliers
              </div>
              <div className="col-6 invalid-range">
                {renderElements(distribution.range00)}
              </div>
            </div>
            
          </Modal.Body>)}
      </Modal>
      
    </LoaderWrapper>
  );
};

export default Exposures;