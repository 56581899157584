import axios from 'axios';

const normalizeRecursive = (node, parentNode, resultNode) => {
  resultNode.id = node.id;
  resultNode.name = node.name;
  resultNode.link = node.link || '';
  resultNode.externalId = node.externalId;
  resultNode.parent = parentNode;
  if (node.children) {
    resultNode.children = [];
    node.children.forEach((childNode) => {
      const childResultNode = {};
      normalizeRecursive(childNode, resultNode, childResultNode);
      resultNode.children.push(childResultNode);
    });
  } 
};

export const normalize = (data) => {
  const resultNode = {};
  normalizeRecursive(data.node, null, resultNode);

  return {
    _id: data._id,
    title: data.title,
    node: resultNode
  }
};

const normalizeOutRecursive = (node, resultNode) => {
  resultNode.id = node.id;
  resultNode.name = node.name;
  resultNode.link = node.link || '';
  resultNode.externalId = node.externalId;
  
  if (node.children) {
    resultNode.children = [];
    node.children.forEach((childNode) => {
      const childResultNode = {};
      normalizeOutRecursive(childNode, childResultNode);
      resultNode.children.push(childResultNode);
    });
  } 
};

const normalizeOut = (data) => {
  const resultNode = {};
  normalizeOutRecursive(data.node, resultNode);

  return {
    ...data,
    node: resultNode
  }
};

export const resolveNodeFile = async (fileName) => {
  const url = `${process.env.REACT_APP_API_URL}/logic-viewer/resolve?file=${fileName}`;
  const response = await axios.get(url);
  return response.data;
};

export const loadNodesNames = async (exceptId) => {
  const query = exceptId ? `?except=${exceptId}` : '';
  const url = `${process.env.REACT_APP_API_URL}/logic-viewer/all${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const loadNodes = async (ids) => {
  const urlIds = ids.map((id) => `ids=${id}`).join('&');
  const url = `${process.env.REACT_APP_API_URL}/logic-viewer/load?${urlIds}`;
  const response = await axios.get(url);
  return response.data || [];
};

export const saveNode = async (data) => {
  const dataToSend = normalizeOut(data);
  const url = `${process.env.REACT_APP_API_URL}/logic-viewer/save`;
  const response = await axios.post(url, dataToSend);
  return response.data;
};

export const deleteNode = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/logic-viewer/${id}`;
  await axios.delete(url);
};