import { atom } from 'recoil';

const AppState = {};

export const STORE = {
  common: {
    isSignedIn: 'common.isSignedIn',
    user: 'common.user',
    profile: 'common.profile',
    symptoms: 'common.symptoms'
  },
  layout: {
    loading: 'layout.loading',
  },
  areas: {
    survey: {
      category: {
        dialog: 'areas.survey.category',
        load: 'areas.survey.category.load',
        select: 'areas.survey.category.select'
      },
      items: {
        load: 'areas.survey.items.load',
        complete: 'areas.survey.items.complete',
      },
      load: 'areas.survey.load',
      save: 'areas.survey.save',
      result: 'areas.survey.result',
    },
    symptom: {
      dialog: 'areas.symptom.dialog',
      load: 'areas.symptom.load',
      save: 'areas.symptom.save',
    },
    profile: {
      find: 'areas.profile.find'
    },
    documents: {
      load: 'areas.documents.load',
      upload: 'areas.documents.upload',
      delete: 'areas.documents.delete',
      download: 'areas.documents.download'
    },
    nutrient: {
      dialog: 'areas.nutrient.dialog',
      dialogDetails: 'areas.nutrient.dialogDetails',
      load: 'areas.nutrient.load',
      loadDates: 'areas.nutrient.loadDates',
      save: 'areas.nutrient.save',
      loadAverage: 'areas.nutrient.loadAverage'
    },
    medicines: {
      dialog: 'areas.medicines.dialog',
      dialogDetails: 'areas.medicines.dialogDetails',
      load: 'areas.medicines.load',
      loadDates: 'areas.medicines.loadDates',
      save: 'areas.medicines.save',
    },
    genetics: {
      resolve: 'areas.genetics.resolve',
      resolveFile: 'areas.genetics.resolveFile',
      resolveProfile: 'areas.genetics.resolveProfile',
      resolveKegg: 'areas.genetics.resolveKegg',
      progressProfile: 'areas.genetics.progressProfile',
      resultProfile: 'areas.genetics.resultProfile',
      geneStats: 'areas.genetics.geneStats',
      crudDialog: 'areas.genetics.crudDialog',
      chartDialog: 'areas.genetics.chartDialog',
      resolvePdfDialog: 'areas.genetics.resolvePdfDialog',
    },
    metaGene: {
      patients: 'areas.metaGene.patients',
      resolve: 'areas.metaGene.resolve',
    },
    medReport: {
      load: 'medReport.load',
      loadProfile: 'medReport.loadProfile',
      symptoms: 'medReport.symptoms'
    },
    logicViewer: {
      resolve: 'logicViewer.resolve',
      loadNames: 'logicViewer.loadNames',
      loadNodes: 'logicViewer.loadNodes',
      saveNode: 'logicViewer.saveNode',
      delete: 'logicViewer.delete',
    }
  }
};

export const getAtom = (key, defaultState) => {
  const existingAtom = AppState[key];
  if (existingAtom) {
    return existingAtom;
  }

  const newAtom = atom({
    key,
    default: defaultState,
  });

  AppState[key] = newAtom;

  return newAtom;
};