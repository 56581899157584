import React, { useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';

import TreeNodeButtons from './TreeNodeButtons';
import { getAction, ACTION, getDisabled } from './treeData';

const TreeNodeEditor = (props) => {
  const { 
    node, 
    requests,
    onAction,
  } = props;

  const [values, setValues] = useState({
    name: node.name,
    link: node.link,
  });

  const [action, setAction] = useState(getAction(node, requests));
  const [disabled, setDisabled] = useState(getDisabled(node, requests));

  const spanRef = useRef(null);
  const inputRef = useRef(null);
  const widthRef = useRef(400);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [action]);

  useEffect(() => {
    const newAction = getAction(node, requests);
    const newDisabled = getDisabled(node, requests);
    setAction(newAction);
    setDisabled(newDisabled);
  }, [node, requests, setAction]);

  const onActionClick = useCallback((targetAction) => {
    if (targetAction.name === ACTION.save.name) {
      node.name = values.name;
      node.link = values.link;
    }
    onAction(targetAction, node);
  }, [values, node, onAction]);

  let viewer = null;
  if (action.name === ACTION.view.name) {
    viewer = (
      <div ref={spanRef}>
        {node.name}
      </div>
    );
  }

  let nameEditor = null;
  if (action.name === ACTION.editText.name) {
    if (spanRef.current?.clientWidth) {
      widthRef.current = spanRef.current.clientWidth - 40;
      if (widthRef.current < 300) {
        widthRef.current = 300;
      }
    }
    const width = `${widthRef.current || 400}px`;
    nameEditor = (
      <div 
        className={classNames('editor-body', ACTION.editText.name)} 
        style={{ width }}>
        <input 
          ref={inputRef}
          value={values.name || ''}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onActionClick(ACTION.save);
            }
            else if (e.key === 'Escape') {
              onActionClick(ACTION.cancel);
            }
          }}
          onChange={(e) => setValues({...values, name: e.target.value })}
          onClick={(e) => e.stopPropagation()} />
      </div>
    );
  }

  let linkEditor = null;
  if (action.name === ACTION.editLink.name || action.name === ACTION.addLink.name) {
    if (spanRef.current?.clientWidth) {
      widthRef.current = spanRef.current.clientWidth - 30;
      if (widthRef.current < 300) {
        widthRef.current = 300;
      }
    }
    const width = `${widthRef.current || 400}px`;
    linkEditor = (
      <div className={classNames('editor-body', ACTION.editLink.name)}
        style={{ width }}>
        <div>{node.name}</div>
        <input 
          ref={inputRef}  
          value={values.link || ''}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onActionClick(ACTION.save);
            }
            else if (e.key === 'Escape') {
              onActionClick(ACTION.cancel);
            }
          }}
          onChange={(e) => setValues({...values, link: e.target.value })}
          onClick={(e) => e.stopPropagation()} />
      </div>
    );
  }
  
  return (
    <div className="node-editor">
      
      {viewer}
      {nameEditor}
      {linkEditor}

      <TreeNodeButtons 
        node={node}
        action={action}
        disabled={disabled}
        onAction={onActionClick} />
    </div>
  );
};


export default TreeNodeEditor;