import React from 'react';

import { Button, Modal } from 'rsuite';

const ConfirmDialog = (props) => {
  const { open, onClose, title, question, okTitle } = props;

  return (
    <Modal className="round-modal"
      open={open} 
      onClose={() => onClose(false)}>
      <Modal.Header />
      <Modal.Body>
        <h2>
          {title}
        </h2>
        <span dangerouslySetInnerHTML={{ __html: question }} />
        <div className="py-2">
          {okTitle && (
            <Button
              appearance="default" 
              className="btn-gadget-2 me-2"
              onClick={() => onClose(true)}>
              {okTitle}  
            </Button>
          )}

          <Button
            appearance="subtle" 
            className="btn-gadget-2"
            onClick={() => onClose(false)}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default ConfirmDialog;