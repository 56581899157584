import React, { useCallback, useState, useRef } from 'react';
import classNames from 'classnames';
import { Animation, Button, IconButton, Input, InputGroup } from 'rsuite';

import moment from 'moment';
import { 
  loadComments, 
  loadPolling, 
  addMessage, 
  editMessage, 
  replyMessage,
  deleteMessage,
  deleteAttachment
} from '../../services/comments';

import { STORE } from '../../store';
import { uploadResultFiles } from '../../services/upload';
import { useAsyncAppState } from '../../hooks/useAsyncAppState';

import Avatar from './Avatar';
import MessageCard from './MessageCard';
import RtfEditor from './RtfEditor';
import ReplyThread from './ReplyThread';
import useConfirmDialog from '../shared/confirm/useConfirmDialog';
import LoaderWrapper from '../../components/common/LoaderWrapper';
import './comments.scss';

const POLLING_INTERVAL = 5;

const Comments = (props) => {
  const { target, className } = props;

  const pollingTimeout = useRef();
  const lastAt = useRef();

  const [visible, setVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [replyId, setReplyId] = useState(null);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const [commentData, setCommentData] = useState({
    user: null,
    messages: { main: [] },
  });

  const [, uploadFilesAsync] = useAsyncAppState(
    STORE.areas.documents.upload, 
    uploadResultFiles);

  const [confirmDeleteAsync, confirmDeleteRender] = useConfirmDialog('Message Delete', 'Yes');
  const [confirmDeleteFileAsync, confirmDeleteFileRender] = useConfirmDialog('File Delete', 'Yes');
  const [cannotDeleteAsync, cannotDeleteRender] = useConfirmDialog('Message Cannot be deleted');
  
  const onPolling = useCallback(async () => {
    if (pollingTimeout.current) {
      clearTimeout(pollingTimeout.current);
    }

    if (lastAt.current) {
      const data = await loadPolling({ target, lastAt: lastAt.current });
      if (data.messages?.length) {
        setCommentData((prev) => {
          const newMessages = { ...prev.messages };
          data.messages.forEach((message) => {
            const parent = message.parentId || 'main';
            const threadMessages = newMessages[parent];
            if (threadMessages) {
              const foundMessage = threadMessages.find((threadMessage) => threadMessage._id === message._id);
              if (foundMessage) {
                foundMessage.message = message.message;
                foundMessage.messageAt = message.messageAt;
                foundMessage.files = message.files;
              }
              else {
                threadMessages.push(message);
              }
            }
            else {
              newMessages[parent] = [message];
            };
          }); 

          return { user: prev.user, messages: newMessages };
        });
      }
    }

    lastAt.current = moment().utc()
      .add(-POLLING_INTERVAL, 'second')
      .format('YYYY-MM-DDTHH:mm:ss.SSS');

    pollingTimeout.current = setTimeout(onPolling, POLLING_INTERVAL * 1000);
  }, [target]);

  const loadCommentsAsync = useCallback(async (filter) => {
    setLoading(true);
    const data = await loadComments({ target, filter });
    
    const email = Object.keys(data.users).find((email) => data.users[email].current);
    const user = data.users[email] || null;

    const messages = { main: [] };
    data.messages.forEach((messageEntity) => {
      if (messageEntity.parentId) {
        if (!messages[messageEntity.parentId]) {
          messages[messageEntity.parentId] = [];
        }
        messages[messageEntity.parentId].push(messageEntity);
      }
      else {
        messages.main.push(messageEntity);
      }
    });

    setCommentData({ user, lastAt, messages });
    setLoading(false);
    onPolling();
  }, [target, setCommentData, onPolling, setLoading]);

  const onToggle = useCallback(async (visible) => {
    const toggledVisible = !visible;
    setVisible(toggledVisible);

    if (toggledVisible) {
      if (!commentData.user) {
        loadCommentsAsync();
      }
    }
    else {
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
      setVisible();  
    }
  }, [commentData, loadCommentsAsync]);

  const onSendFiles = useCallback(async (files) => {
    let result;
    if (files?.length) {
      result = await uploadFilesAsync({ files });
    }
    return result ? result.map((file) => file.data) : null;
  }, [uploadFilesAsync])

  const onSend = useCallback(async (message, files) => {
    if (commentData.user && Boolean(message)) {

      setLoading(true);
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
      try {
        const uploadedFiles = await onSendFiles(files);
        await addMessage({
          email: commentData.user.email,
          target,
          message,
          files: uploadedFiles
        });
      }
      finally {
        setLoading(false);
      }
      onPolling();
    }
  }, [target, commentData, onPolling, onSendFiles]);

  const onEditSend = useCallback(async (_id, message, files) => {
    if (commentData.user && Boolean(message)) {
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
      try {
        setLoading(true);
        const uploadedFiles = await onSendFiles(files);
        await editMessage({
          _id,
          target,
          email: commentData.user.email,
          message,
          files: uploadedFiles
        });
      }
      finally {
        setLoading(false);
      }
      onPolling();
    }
  }, [target, commentData, onPolling, onSendFiles]);

  const onReplySend = useCallback(async (parentId, replyToId, message, files) => {
    if (commentData.user && Boolean(message)) {
      try {
        setLoading(true);
        if (pollingTimeout.current) {
          clearTimeout(pollingTimeout.current);
        }
        const uploadedFiles = await onSendFiles(files);
        await replyMessage({
          email: commentData.user.email,
          replyToId,
          parentId,
          target,
          message,
          files: uploadedFiles
        });
      }
      finally {
        setLoading(false);
      }
      onPolling();
    }
  }, [target, commentData, onPolling, onSendFiles]);

  const onFilterClearClick = useCallback(async () => {
    setVisible(true);
    setFilter('');
    await loadCommentsAsync('');
  }, [setVisible, setFilter, loadCommentsAsync]);

  const onFilterClick = useCallback(async () => {
    if (filter.length > 2) {
      setVisible(true);
      await loadCommentsAsync(filter);
    }
    else if (!filter.length) {
      await onFilterClearClick();
    }
  }, [filter, setVisible, loadCommentsAsync, onFilterClearClick]);

  const onDeleteMessage = useCallback(async (data) => {
    if (commentData.user && Boolean(data)) {
      if (commentData.messages[data._id]?.length > 0) {
        await cannotDeleteAsync(`Message: "${data.message}" has replies and cannot be deleted.`);
        return;
      }
      
      if (await confirmDeleteAsync(`Delete message: "${data.message}"?`)) {
        if (pollingTimeout.current) {
          clearTimeout(pollingTimeout.current);
        }

        try {
          setLoading(true);
          const deleteData = await deleteMessage(data._id);
          if (deleteData.id) {
            setCommentData((prev) => {
              const newMessages = { ...prev.messages };
              Object.keys(newMessages).forEach((parent) => {
                const threadMessages = newMessages[parent];
                newMessages[parent] = threadMessages.filter((message) => message._id !== deleteData.id);
              });
              return { user: prev.user, messages: newMessages };
            });
          }
        }
        finally {
          setLoading(false);
        }

        onPolling();
      }
    }
  }, [commentData, confirmDeleteAsync, cannotDeleteAsync, onPolling]);

  const onDeletedAttachment = useCallback(async (data, fileId) => {
    if (pollingTimeout.current) {
      clearTimeout(pollingTimeout.current);
    }
    try {
      setLoading(true);
      await deleteAttachment({ id: data._id, fileId });
    }
    finally {
      setLoading(false);
    }
    onPolling();
  }, [setLoading, onPolling]);

  return (
    <LoaderWrapper loading={loading} show>
      <div className={classNames(className, 'comment-container')}>
        <nav className="nav-comments">
          <h3>Comments</h3>
          <div className="d-flex align-items-center">
            <InputGroup inside className="input-search me-3">
              <InputGroup.Addon>
                <span className="material-icons">search</span>
              </InputGroup.Addon>
              <Input placeholder="Type here..." 
                value={filter} 
                onKeyUp={(e) => e.key === 'Enter' && onFilterClick(e.target.value)}
                onChange={setFilter}/>
              <InputGroup.Addon>
                <IconButton 
                  circle 
                  size="sm"
                  style={{ margin: '-1rem' }}
                  onClick={onFilterClearClick}
                  icon={<span className="material-icons">clear</span>} 
                  appearance="subtle" />
              </InputGroup.Addon>  
            </InputGroup>

            <Button
              appearance="default" 
              className="btn-gadget-2 btn-search" 
              onClick={onFilterClick}>
              Filter
            </Button>

            <div>
              <IconButton 
                circle 
                onClick={() => onToggle(visible)}
                icon={<span className="material-icons">{visible ? 'arrow_drop_up' : 'arrow_drop_down'}</span>} 
                appearance="subtle" />
            </div>  
          </div>  
        </nav>  

        <Animation.Collapse in={visible}>
          <div>
            <div className="message-container no-border">
              <div className="message-body">
                <div className="message-intend">
                  <Avatar color={commentData.user?.avatar} />
                </div>
                <div className="message-content centered">
                  <RtfEditor 
                    onSend={onSend}
                    disabled={!commentData.user || Boolean(editId) || Boolean(replyId)} />
                </div>
              </div>
            </div>

            {commentData.messages.main.map((messageData) => 
              <MessageCard key={messageData._id} 
                user={commentData.user}
                data={messageData}
                filter={filter}
                replyCount={commentData.messages[messageData._id]?.length}
                replyParent={messageData}
                replyId={replyId}
                editId={editId}
                setReplyId={setReplyId}
                setEditId={setEditId}
                onReplySend={onReplySend}
                onEditSend={onEditSend}
                onDelete={onDeleteMessage}
                onDeleteFileStart={confirmDeleteFileAsync}
                onDeleteFileEnd={onDeletedAttachment}
              >
                <ReplyThread
                  user={commentData.user}
                  parent={messageData}
                  messages={commentData.messages}
                  filter={filter}
                  replyId={replyId}
                  editId={editId}
                  setReplyId={setReplyId}
                  setEditId={setEditId}
                  onReplySend={onReplySend}
                  onEditSend={onEditSend} 
                  onDelete={onDeleteMessage}
                  onDeleteFileStart={confirmDeleteFileAsync}
                  onDeleteFileEnd={onDeletedAttachment}
                />
              </MessageCard>
            )}
          </div>  
        </Animation.Collapse>
        {confirmDeleteRender()}
        {cannotDeleteRender()}
        {confirmDeleteFileRender()}
      </div>
    </LoaderWrapper>
  );
}

export default Comments;
