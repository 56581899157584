import React, { useCallback, useState } from 'react';

import TreeNode from './TreeNode';
import './treeView.scss';

const gatherParents = (node, result) => {
  result.push(node);
  if (node.parent) {
    gatherParents(node.parent, result);
  }
};

const Tree = (props) => {
  const { node, onOpenUrl, onSelect } = props;
  const [selectedIds, setSelectedIds] = useState([]);

  const onSelectNode = useCallback((node) => {
    const selection = [];
    gatherParents(node, selection);

    const nodeIds = selection.map((node) => node.id);
    setSelectedIds(nodeIds);

    if (onSelect) {
      onSelect(selection);
    }
  }, [onSelect, setSelectedIds]);

  const onUrlNode = useCallback((node) => {
    onSelectNode(node);
    if (onOpenUrl) {
      onOpenUrl(node.link);
    }
  }, [onOpenUrl, onSelectNode]);

  if (!node) {  
    return null;
  }

  return (
    <TreeNode 
      node={node}
      selectedIds={selectedIds}
      onSelect={onSelectNode} 
      onOpenUrl={onUrlNode}
      isRoot 
      isExpanded />
  );
};


export default Tree;