import moment from 'moment';

export const SYMPTOMS_RANGE_TYPE = {
  none: 'None', 
  daily: 'Daily', 
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  lastVisit: 'From Last Visit'
};

export const defineRange = (options) => {
  options.to = moment(options.max).toDate();

  if (options.rangeType === SYMPTOMS_RANGE_TYPE.yearly) {
    options.from = moment(options.max).startOf('year').toDate();
  }
  else if (options.rangeType === SYMPTOMS_RANGE_TYPE.monthly) {
    options.from = moment(options.max).startOf('month').toDate();
  }
  else if (options.rangeType === SYMPTOMS_RANGE_TYPE.weekly) {
    options.from = moment(options.max).startOf('week').toDate();
  }
  else if (options.rangeType === SYMPTOMS_RANGE_TYPE.daily) {
    options.from = moment(options.max).startOf('day').toDate();
  }
};

export const mapTimeUnit = (rangeType, isLess) => {
  if (rangeType === SYMPTOMS_RANGE_TYPE.weekly) {
    return isLess ? 'days' : 'weeks';
  }
  else if (rangeType === SYMPTOMS_RANGE_TYPE.monthly) {
    return isLess ? 'weeks' : 'months';
  }
  else if (rangeType === SYMPTOMS_RANGE_TYPE.yearly) {
    return isLess ? 'months' : 'years';
  }
  else {
    return isLess ? 'hours' : 'days';
  }
};

let globalTimeUnit = 'days';

export const normalizeAmount = (amount) => {
  if (amount > 10) {
    return Math.round(amount);
  }
  else {
    return Math.round(amount * 100) / 100;
  }
};
 
export const getChartOptions = () => {
  return {
    responsive: true,
    plugins: {
      title: {
        display: false,
      }
    },
    interaction: {
      intersect: false,
    },
    elements: {
      point: {
        radius: (context) => {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value ? value.y * 2 : 0;
        },
        backgroundColor: (context) => context.element?.options?.borderColor
      }
    },
    scales: {
      x: {
        title: {
          display: true
        },
        type: 'time',
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            const mValue = moment(value);
            if (mValue.startOf(globalTimeUnit).toDate().getTime() === value) {
              return mValue.format(globalTimeUnit === 'hours' ? 'HH:mmA' : 'DD MMM');
            }
            return null;
          },
          font: {
            family: 'AzoSans',
          }
        },
      },
      y: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            if (value === 10) {
              return 'HIGH';
            }
            else if (value === 5) {
              return 'AVG';
            } 
            else if (value === 0) { 
              return 'LOW'
            }
            else {
              return null;
            }
          },
          font: {
            family: 'AzoSans',
            weight: 'bold'
          }
        },
        suggestedMin: 0,
        suggestedMax: 10
      }
    }
  }
};

export const getChartData = (selected, colors, symptoms, options) => {
  const dates = [];
  globalTimeUnit = mapTimeUnit(options.rangeType, true);
  
  let tickDate = moment(options.from).startOf(globalTimeUnit).toDate();
  const tickToDate = moment(options.to).toDate();
 
  while (tickDate <= tickToDate) {
    dates.push(tickDate);
    tickDate = moment(tickDate).add(1, globalTimeUnit).toDate();
  }

  const datasets = selected.map((category) => {
    const categorySymptoms = symptoms[category] || [];
    const data = categorySymptoms.map((symptom) => {
      return {
        x: moment(symptom.start).toDate(),
        y: symptom.severity,
      }
    });

    return {
      label: category,
      data,
      borderColor: colors[category],
      fill: false,
      cubicInterpolationMode: 'monotone',
      tension: 0.4
    }
  });

  const sortedDates = dates.sort((left, right) => left.getTime() - right.getTime());

  return {
    labels: sortedDates,
    datasets
  }
};
