import React, { useState } from 'react';
import moment from 'moment';
import { Animation, Button }from 'rsuite';
import searchTextHL from 'search-text-highlight'

import RtfEditor from './RtfEditor';
import Avatar from './Avatar';
import Indent from './Indent';
import Attachments from './Attachments';

const replyToTranform = (messageData) => {
  return messageData.messageReplyTo ? 
    `<citate>${messageData.messageReplyTo}</citate> ${messageData.message}` : 
    messageData.message;
};

const MessageCard = (props) => {
  const [expanded, setExpanded] = useState(true);
  const { 
    user, 
    data,
    filter,
    replyCount,
    replyParent,
    editId, 
    replyId,
    setEditId, 
    setReplyId,
    onEditSend,
    onReplySend, 
    onDelete,
    onDeleteFileStart,
    onDeleteFileEnd,
    children 
  } = props;

  const isOwn = user?.email === data.from.email;
  const isEdit = data._id === editId;

  return (
    <div className="message-container">
      <div className="message-header">
        {data.from?.avatar && <Avatar color={data.from.avatar} />}
        <span className="message-from me-1">{data.from?.name}</span>
        <span className="message-date">| {moment(data.messageAt).format('MMM DD, YYYY hh:mm A')}</span>
        {isOwn && (
          <div className="ms-2">
            <Button 
              className="message-edit" 
              onClick={() => {
                setReplyId(null); 
                setEditId(isEdit ? null : data._id)
              }}
              appearance="subtle">
              <span className="material-icons">{isEdit ? 'close' : 'edit'}</span>  
            </Button>    

            <Button 
              className="message-edit" 
              onClick={() => {
                setReplyId(null);
                setEditId(isEdit);
                onDelete(data);
              }}
              appearance="subtle">
              <span className="material-icons">delete_forever</span>  
            </Button>    
          </div>
        )}
      </div>
      
      <div className="message-body">
        <div className="message-content">
          {!isEdit && (
            <div className="d-flex">
              <Indent className={replyCount && expanded ? 'intend-line' : 'intend-empty'} />
              <span className="pb-2" dangerouslySetInnerHTML={{ 
                __html: searchTextHL.highlight(replyToTranform(data), filter) 
              }} />
            </div>
          )}

          {isEdit && (
            <div className="d-flex">
              <Indent className={replyCount && expanded ? 'intend-line' : 'intend-empty'} />
              <RtfEditor value={data.message} onSend={(message, files) => {
                onEditSend(data._id, message, files);
                setEditId(null);
              }} />
            </div>
          )}

          {data?.files?.length > 0 && (
            <div className="d-flex">
              <Indent className={replyCount && expanded ? 'intend-line' : 'intend-empty'} />
              <Attachments 
                files={data.files} 
                onDeleteStart={onDeleteFileStart}
                onDeleteEnd={(fileId) => onDeleteFileEnd(data, fileId)} 
              />
            </div>      
          )}

          <div className="d-flex">
            <Indent className={replyCount && expanded ? 'intend-line' : 'intend-empty'} />
            <div>
              <Button 
                className="message-reply me-3"
                onClick={() => { 
                  setEditId(null); 
                  setReplyId(replyId === data._id ? null : data._id) 
                }} 
                appearance="subtle"
              >
                {replyId === data._id ? 'Cancel': 'Reply'}
                <span className="material-icons">{replyId === data._id ? 'close' : 'arrow_forward' }</span>
              </Button>

              {replyCount > 0 && (
                <Button 
                  className="message-reply"
                  onClick={() => setExpanded(!expanded)} 
                  appearance="subtle"
                >
                  {expanded ? 'Hide' : 'Expand'} ({replyCount})
                  <span className="material-icons">{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                </Button>
              )}
            </div>

            {replyId === data._id && (
              <RtfEditor onSend={(message, files) => {
                onReplySend(replyParent._id, data._id, message, files);
                setReplyId(null);
              }} />
            )}
          </div>
          {replyCount > 0 ? <Animation.Collapse in={expanded}><div>{children}</div></Animation.Collapse> : children}
        </div>
      </div>
    </div>
  )
};

export default MessageCard;
