import React, { useCallback } from 'react';

import { Button } from 'rsuite';

import { downloadFile } from '../../services/upload';

const getFileSize = (size) => {
  if (!size) {
    return ''
  };

  const kb = 1020;
  const mb = 1020 * 1024;

  let value = 0;
  if (size < kb) {
    value = `${Math.round(size)}B`
  }
  else if (size < mb) {
    value = `${Math.round(10 * size / kb) / 10}KB`
  }
  else {
    value = `${Math.round(10 * size / mb) / 10}MB`
  }

  return value;
};

const Attachments = ({ files, onDeleteStart, onDeleteEnd }) => {
  const onDownload = useCallback((fileId, fileName) => {
    downloadFile({ fileId, fileName });
  }, []);

  const onDelete = useCallback(async (fileId, fileName) => {
    if (await onDeleteStart(`Delete Attachment ${fileName}?`)) {
      await onDeleteEnd(fileId);
    }
  }, [onDeleteStart, onDeleteEnd]);

  return (
    <div className="attachments">
      <div className="attachments-header">
        <span className="material-icons">attach_file</span>
        <span className="attachments-title">Attachments</span>
      </div>
      <div className="attachments-body">
        {files.map((fileData) => (
          <div key={fileData._id} className="attachment-content">
            <Button 
              className="attachment-item"
              appearance="subtle"
              onClick={() => onDownload(fileData._id, fileData.name)}
            >
              <span className="material-icons">description</span>
              <span className="attachments-item-title">{fileData.name}</span>
              <span className="attachments-item-size">{getFileSize(fileData.size)}</span>
            </Button>

            {onDeleteStart && (
              <Button 
                className="message-edit" 
                onClick={() => onDelete(fileData._id, fileData.name)}
                appearance="subtle">
                <span className="material-icons">delete_forever</span>  
              </Button>
            )}
          </div>
        ))}
      </div>  
    </div>
  );
};

export default Attachments;
