import { useState, useCallback } from 'react';
import { Accordion, IconButton } from 'rsuite';
import WarningRound from '@rsuite/icons/WarningRound';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';

import { STORE } from '../../store';
import { useAsyncAppState  } from '../../hooks/useAsyncAppState';
import { resolveMapFile } from '../../services/mapEditor';

import LoaderWrapper from '../../components/common/LoaderWrapper';
import UploadDocsForm from '../../rs-components/shared/uploader/UploadDocsForm';

import LogicViewerLayout from './LogicViewerLayout';

import Tree from '../shared/tree/Tree';
import { getNetData } from './viewerData';

import './logicViewer.scss';

const LogicViewerPage = () => {
  const [result, setResult] = useState(null);
  const [viewUrl, setViewUrl] = useState('');
  const [resolveFile, resolveFileAsync] = useAsyncAppState(
    STORE.areas.genetics.resolveFile,
    resolveMapFile
  );

  const onShowUrl = useCallback((url) => {
    setViewUrl(url);
  }, [setViewUrl]);

  const onDeleteTree = useCallback((file) => {
    if (result) {
      setResult(result.filter((item) => item.file !== file));
    }
  }, [result, setResult]);
  
  const onUpload = useCallback(async (uploadResult) => {
    const newResult = [];
    for (let uploadItem of uploadResult) {
      const fileResult = await resolveFileAsync(uploadItem.file.name);
      newResult.push(getNetData(uploadItem.file.name, fileResult));
    }
    setResult(newResult);
  }, [resolveFileAsync]);

  return (
    <LogicViewerLayout viewUrl={viewUrl}>
      <LoaderWrapper items={[resolveFile]} show>
        <div>
          <div className='title-help-large text-uppercase d-flex align-items-center mb-2'>
            <span>Logic Viewer</span>
          </div>
          <UploadDocsForm onRefresh={onUpload} isUnique />
          <hr />
        </div>

        {result?.length > 0 && (
          <Accordion className="logic-accordion">
            {result.map((result) => (
              <Accordion.Panel 
                key={result.file}
                className="logic-tree"
                caretAs={ArrowDownLineIcon}
                header={(
                  <div className="file-name">
                    <IconButton
                      size="lg"
                      color="red"
                      onClick={() => onDeleteTree(result.file)} 
                      circle appearance="link" 
                      icon={<WarningRound />} />
                    {result.file}  
                  </div>
                )}>
                  
                <Tree 
                  node={result.node} 
                  onOpenUrl={onShowUrl} 
                  onSelect={() => {}} />

              </Accordion.Panel>
            ))}
          </Accordion>
        )}
      </LoaderWrapper>
    </LogicViewerLayout>
  )
};

export default LogicViewerPage;