import axios from 'axios';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { getToken, startSession, endSession } from './services/session';
import App from './App';

axios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['x-access-token'] = token;
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  }, 

  async (error) => {
    if (error.response) {
      if (error.response.status === 406) {
        console.log('Token Refresh Attempt');
        const tokenResponse  = await axios.get(`${process.env.REACT_APP_API_URL}/refresh`, { 
          withCredentials: true 
        });
        console.log('Token Refresh Successfull');
        startSession(tokenResponse.data.token);
        return await axios.request(error.config);
      }
      else if (error.response.status === 401 ||  error.response.status === 403) {
        endSession();
        document.location.assign('/');
        return null;
      }
      else {
        throw error;
      }
    }
    else {
      throw error;
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
